@import "../../themes/colors.scss";
@import "../../styles/variable";

.cancel-icon {
  text-align: end;
}

.deleteIcon {
  text-align: center;
}

.modalModalHeading {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 800;
  font-size: 25px;
  color: $color_purple;
  font-family: $primaryFont;
}

.divButtons {
  text-align: center;
}

.cancelBtn {
  border: 1px solid $secondaryColor;
  font-weight: 500;
  font-size: 13px;
  background-color: $secondaryColor;
  border-radius: 4px;
  color: #212529;
  margin: 0px 5px;
  height: 36px;

  &:hover {
    border: 1px solid $dark_secondaryColor;
    background-color: $dark_secondaryColor;
  }
}

.cancelIcon{
  position: absolute;
  top: 12px;
  right: 25px;
  cursor: pointer;
}