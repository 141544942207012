.otherInput {
  div {
    background-color: white;
    height: 27px !important;
    width: 120px !important;
  }
}

.wd_40 {
  width: 40%;
  background: rgb(229, 246, 237);
  font-size: 13px;
  font-style: normal;
  padding: 14px;
  font-weight: 700;
  line-height: normal;
}

.graphButtons{
  .deleteButton{
    margin-left: 15px;
    cursor: pointer;
  }
}

.updateGraph{
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.phaseChart_container{
  padding: 15px 0px 15px 0px;
}

.pctWallStyle{
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #00a751;
  color: white;
}

.GraphContainer {
  height: 92vh;
}

.pctWall {
  width: 80px
}

.GraphTableStyle {
  background-color: white;
}

.tableRowStyle{
  height: 60px;
}

.cursorPointer{
  cursor: pointer;
}