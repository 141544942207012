@import "../../themes/colors.scss";
@import "../../styles/globalmixin.scss";
@import "../../styles/breakpoint";
@import '../../styles/variable';

.inventoryImage {
  width: 150px;
  height: 150px;
  img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.textInventory {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  color: #092245;
  margin-bottom: 10px;
}

.textDescriptionInventory {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #878a99;
  width: 80%;
}

.headingDataTable {
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  background-color: #FFFFFF;
  padding: 20px;
  border-bottom: 1px solid #E9EBEC;
}

.inventoryCards {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  cursor: pointer;
}

.inventoryCards:hover {
  border-color: #00A751;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.bottomButtons{
  background-color: #FFFFFF;
  padding: 20px;
}

.inventoryContent{
  width: 80%;
}

.rejectBtn {
  border: 1px solid $color_orange !important;
  font-weight: 500;
  font-size: 13px;
  background-color: $color_orange !important;
  border-radius: 4px;
  color: $white !important;
  padding: 9px 18px !important;
  text-transform: capitalize !important;
  height: 40px;
  &:hover{
    border: 1px solid $color_dark_orange !important;
    background-color: $color_dark_orange !important;
    color: $white !important; 
  }
}