.DialogContentStyle{
  padding: 12px !important;
}

.tableHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 9px 20px;
  border-bottom: 1px solid #E9EBEC;
}

.tableHeadingText {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #212529;
}

.checkListButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.pdfFileName {
  display: flex;
  justify-content: flex-end;
  label{
  font-size: 13px;
  font-weight: 500;
  padding: 8px 0px;
  color: #00a751;
  }
}

.SendEmailEditor{
  div{
    div{
      select{
        display: flex;
        align-items: center;
        font-size: 14px !important;
      }
    }
    p{
      font-size: 13px !important;
      font-weight: 500 !important;
      font-family: "Inter", sans-serif !important;
      margin-bottom: 0px !important;
      height: 200px;
      overflow: auto;
    }
  }
}

.CharacterCount {
  display: flex;
  justify-content: flex-start;
  span{
  font-size: 13px;
  font-weight: 500;
  padding: 8px 0px;
  color: #00a751;
  }
}

.textUnderLine{
  width: 100%;
  text-decoration: underline 1px;
  color: #1577d9 !important;
}