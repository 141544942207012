.main_container{
    padding: 40px 63px;
    // max-width: 540px;
    width: 100%;
    .logout_btn {
        box-shadow: none;
        padding: 10px 18px;
        text-transform: capitalize;
        background-color: #00A751;
        border-radius: 4px;
        font-weight: 500;
        &:hover {
            box-shadow: none;
            background-color: rgb(2, 186, 91);
        }
    }
    .logout_cancel_btn {
        box-shadow: none;
        padding: 10px 18px;
        text-transform: capitalize;
        background-color: #CED4DA;
        border-radius: 4px;
        font-weight: 500;
        color: #212529;
        &:hover {
            box-shadow: none;
            background-color: #dce2e8;
        }
    }
}

.first_div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2{
        font-size: 25px;
        font-weight: 800;
        padding: 30px 0 40px 0;
        margin: 0;
    }
}

.second_div{
    display: flex;
    justify-content: center;
    align-content: center;
}
