
@import "../../../../../themes/colors.scss";
@import "../../../../../styles/globalmixin.scss";

  .customerInfo {
    font-weight: 600;
    font-size: 15px;
    color: $cyanBlueColor;
  }
  .customerInfo_main_container {
    padding: 16px 20px;
  }

  .div_label_text {
    display: flex;
    flex-direction: column;
    min-width: 70px;
  }
  .no_label_Style {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 30px;
  }
  .label_Style_Customer {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 9px;
  }      

  .dropdown_container {
  display: flex;
  align-items: center;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root{
  box-shadow: none !important;
}

.markup_dropdown{
  width: 150px !important;
  height: 28px !important;
}

@media screen and (max-width: 600px) {
  .markup_dropdown {
    width: 100% !important;
    height: auto !important;
  }
}

.billing-checkbox{
  display: flex;
  justify-content: flex-end;
}
.div_container_CustomerInfo {
  // margin: 20px 0px;
  background: #f3f3f9;
}
.save_and_next_div {
  @include display_items(flex, flex-end, "");
}

.button_margin_left {
  margin-left: 16px;
}


.css-pwt5s4 {
   padding: 0px !important;
   border: 1px solid #e9ebec !important
  }