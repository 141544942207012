@import '../../../../../themes/colors.scss';
@import "../../../../../styles/globalmixin.scss";

.customerInfo_main_container {
  padding: 16px 20px;
}

.customerInfo {
  font-weight: 600;
  font-size: 15px;
  color: #212529;
}

.div_label_text {
  display: flex;
  flex-direction: column;
}

.no_label_Style {
  font-weight: 500;
  font-size: 13px;
  color: $cyanBlueColor;
  margin-bottom: 30px;
}

.label_Style_Customer {
  font-weight: 500;
  font-size: 13px;
  color: $cyanBlueColor;
  margin-bottom: 9px;
}

.save_and_next_div {
  @include display_items(flex, flex-end, "");
}

.button_margin_left {
  margin-left: 16px;
}


.div_checkbox {
  justify-content: center;
  margin-top: 10px;
  display: flex;
}