.tablecell_div_style{
    display: flex;
    align-items: center; 
}

.select_style{
    background-color: white !important;
    height: 28px !important; 
}

.manualVessel {
    min-width: 165px !important;
    div {
        height: 28px !important;
        background-color: white !important;
    }
}