@import '../../themes/colors.scss';
@import '../../styles/variable';

.wd-33{
  width: 33.33%;
}

.wd-15{
  width: 15%;
}
.wd-25{
  width: 25%;
}
.wd-60{
  width: 60%;
}
.wd-50{
  width: 49%;
}
.text-end{
  text-align: end;
}
@media screen and (max-width: '600px') {
  .noScroll{
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .mob-width-515 {
    width: 515px;
  }
  .text-right-aligned{
    text-align: right;
  }
  .oilReportTableContainer{
    overflow-x: auto;
  }
}
.pdfEmailSystemContainer{
  display: flex;
  justify-content: space-between;
  border: 1px solid #E9EBEC;
  border-radius: 6px;
  padding: 16px 23px 20px 0px;
  .CustomSearchTable{
    padding-left: 20px;
  }
  .rightSideEmailBorderModal{
    border: 1px solid #00A751;
    background-color: #FFFFFF;
    padding: 20px 20px 10px 20px;
    border-radius: 6px;
  }

  .editorContainer{
    position: relative;
    .demo-editor{
      background-color: #FFFFFF !important;
    }
  .attachmentButton{
    position: absolute;
    top: 8px;
    right: 12px;
    .MuiButton-contained{
      background-color: #F06548 !important;
      border-radius: 4px !important;
      min-height: 32px !important;
      min-width: 32px !important;
    }
  }
  }
  .customScroll{
    max-height: calc(100vh - 340px);
    overflow-y: auto;
  }
  .save_and_next_div{
    margin-top: 16px;
  }
}

.oilReportEmailBody{
  .oilReportTableList{
    display: flex;
    flex-direction: column;
    .oilReportTableRowData{
      border: 1px solid #E9EBEC;
      display: flex;
      width: 100%;
      .oilReportTableCell{
        border: none !important;
        .actionButtons{
          cursor: pointer;
        }
      }
      .container_checkmark_div{
        margin-right: 0px;
        .container_checkmark {
          padding-left: 29px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customMobpad{
    padding: 12px 0px !important;
  }
  .pdfEmailSystemContainer{
    flex-direction: column;
    .rightSideEmailBorderModal{
      margin-top: 20px;
    }
  }
  .wd-50{
    width: 100%;
  }
  .pdfEmailSystemContainer {
    padding: 10px;
    .customScroll {
      max-height: calc(100vh - 475px);
    }
}
.oilReportTableBody {
  max-height: calc(100vh - 528px);
}
}