@import "../../styles/variable";

.wdth100 {
  width: 100% !important;
}

.mainCalendarContainer {
  overflow: auto;
  width: calc(100% - 16px);
  padding: 18px;
  background-color: #ffffff;
  @media screen and (max-width: '600px') {
    width: 100%;
    padding: 15px;
  }
}

.mainWeekContainer {
  display: flex;
  flex-direction: row;
  position: sticky;
    top: -18px;
    z-index: 2;
    background-color: white;
}

.weekTab {
  border: 3px solid #ffffff;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: #f3f3f9;
  color: #000000;
  font-size: 11px;
  letter-spacing: 4px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  &::after {
    position: absolute;
    top: 6px;
    right: 3px;
    width: 22px;
    height: 22px;
    content: "";
    background-image: url("../../assets/Icons/arrowIcon.svg");
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }
  &::before {
    position: absolute;
    top: 5px;
    left: 3px;
    width: 22px;
    height: 22px;
    content: "";
    background-image: url("../../assets/Icons/arrowIcon.svg");
    background-repeat: no-repeat;
  }
}

.arrowIconStyle {
  cursor: pointer;
}
.blankWeekTab {
  &::after,
  &::before {
    background-image: none !important;
  }
}

.analystContainer {
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
  height: 48vh;
}

.topDaysContainer {
  display: flex;
}

.anaylistCalendarDateBox {
  display: flex;
  flex-direction: row;
  place-items: center;
  @media screen and (max-width: '600px') {
    width: 100%;
  }
}
.analystBox {
  width: 200px;
}
.analystCell {
  width: 40px;
  height: 58px;
  border: 1px solid #ced4da;
  cursor: pointer;
  .analystCell {
    border: none;
    cursor: pointer;
  }
}

.labellingContainer {
  padding: 20px;
  border-bottom: 1px solid #e9ebec;
  button {
    margin-right: 8px;
    background-color: #00a751 !important;
    color: white !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    padding: 6px 18px;
  }
}

.labellingBox {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  place-items: center;
  .calendarLabelling {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    place-items: center;
    font-size: 12px;
    font-weight: 500;
    color: $color_grey;
    margin-left: 30px;
    white-space: nowrap;
    &:first-child {
      margin-left: 0px;
    }
    span {
      min-width: 28px;
      height: 28px;
      border-radius: 4px;
      display: inline-block;
      margin: 0 8px;
    }
  }
}

.monthCalendarAnalystNameContainer {
  place-items: center;
  display: flex;
  justify-content: flex-start;
}

.monthCalendarAnalystName {
  font-size: 20px;
  color: #00a751;
  font-weight: 600;
  line-height: 21px;
}
.customMonthCalendarFilterBox {
  display: flex;
  justify-content: flex-end;
  place-items: center;
}
.customMonthCalendarFilter {
  width: 75%;
  margin-left: 6px;
  @media screen and (max-width: '600px') {
    width: 100%;
    margin-left: 0px;
  }
}
.calendarDropdownFilterBox {
  padding: 0 20px 16px 20px;
  border-bottom: 1px solid #e9ebec;
  label {
    font-size: 13px;
    font-weight: 500;
    color: #212529;
    margin-bottom: 8px;
  }
  .customCalendarFilterSelect {
    width: 100%;
  }
}

.leftCalendarBox {
  min-width: 320px;
  cursor: pointer;
  height: 59px;
  display: flex;
  align-items: center;
  background-color: white;
  position: sticky;
  left: -18px;
  padding-left: 12px;
  z-index: 2;
}

.rightCalendarBox {
  display: flex;
  background-color: white;
}

.analystHeadingContainer {
  display: flex;
  justify-content: flex-start;
  place-items: flex-end;
  height: 92px !important;
  align-items: center !important;
  .analystHeading {
    font-weight: 600;
    font-size: 15px;
    color: #092245;
    // margin-bottom: 20px;
  }
}

.calenderDateCell {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  place-items: flex-end;
  font-size: 11px;
  font-weight: 600;
  height: 25px;
  cursor: default;
}

.calenderDayCell {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  place-items: flex-start;
  font-size: 11px;
  font-weight: 500;
  height: 25px;
  cursor: default;
}

.addEventModalContainer {
  padding: 6px 10px 10px 10px !important;
}

.calendarRedTextColor {
  color: #f06548;
}
.calendarBlackTextColor {
  color: #000000;
}
.calendarGreenBGColor {
  background-color: #e5f6ee;
}

.calendarRedBGColor {
  background-color: #f0654866;
}

.calendarGreenTextColor {
  color: #00a751;
}

.jobScheduleColor {
  background-color: #908380;
}
.appointmentColor {
  background-color: #f4f9d6;
}
.sickLeaveColor {
  background-color: #bec4aa;
}
.vacationColor {
  background-color: #99b3a8;
}

// tooltip Styles
.tooltipJobScheduleColor {
  background-color: #00a751 !important;
  // color: #ffffff;
}
.tooltipAppointmentColor {
  background-color: #fc7d4e !important;
  // color: #ffffff;
}
.tooltipSickLeaveColor {
  background-color: #fc7d4e !important;
  // color: #ffffff;
}
.tooltipVacationColor {
  background-color: #fc7d4e !important;
  // color: #ffffff;
}

.tooltipHolidayColor {
  background-color: #fc7d4e !important;
  // color: #ffffff;
}

.calendarTabContainer {
  max-height: 165px;
  overflow: auto;
}
.tabAppointmentList {
  width: 100%;
  color: #212529;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 8px;
  &:last-child {
    border-bottom: none;
  }
  .tabAppointmentTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 33.6px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .tabAppointmentDateRange {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .tabAppointmentContact {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    img {
      margin-right: 8px;
    }
  }
  .tabAppointmentActionBox {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .tabAppointmentActionButton {
      span {
        margin-right: 10px;
        cursor: pointer;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .tabAppointmentActionValue {
      display: flex;
      place-items: center;
      span {
        width: 10px;
        height: 10px;
        margin-right: 12px;
        border-radius: 50%;
        display: block;
        margin-left: 5px;
      }
    }
  }
}

.tabJobList {
  display: flex;
  justify-content: space-between;
  place-items: baseline;
  margin-bottom: 12px;
  .jobLabel {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    place-items: center;
    span {
      width: 10px;
      height: 10px;
      margin-right: 12px;
      border-radius: 50%;
      display: block;
      margin-left: 5px;
    }
  }
  .infoBtn {
    cursor: pointer;
  }
}

.borderBottom {
  border-bottom: 1px solid #e9ebec;
  width: 100%;
  margin-top: 25px;
}

.jobScheduleHeaderId {
  display: flex;
  place-items: center;
}
.headerJobId {
  display: flex;
  place-items: center;
  margin-right: 25px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  span {
    border-radius: 4px;
    background-color: #00a751;
    color: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 29px;
    padding: 7px 14px;
    justify-content: center;
    align-items: center;
  }
}

.jobScheduleModalContainer {
  padding: 0px !important;
  overflow: hidden;
  // overflow-y: auto;
  // max-height: calc(100vh - 230px);
}

.bookmarkBg {
  background-color: #f7b84b;
  color: white;
  cursor: pointer;
  .calenderDateCell,
  .calenderDayCell {
    cursor: pointer;
  }
}

.jobLabelContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 16px;
  background-color: #f3f3f9;
  margin: 15px 0px;
  .jobLabels {
    display: flex;
    justify-content: flex-start;
    place-items: center;
    background: #e5f6ee;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
    width: fit-content;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-right: 20px;
    min-width: 130px;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0px;
    }
    img {
      margin-right: 8px;
    }
  }
}

.JobScheduleBottomContainer {
  .JobScheduleHeading {
    color: #212529;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9ebec;
  }
  .JobScheduleTable {
    padding-top: 25px;
    table {
      width: 100%;
      border: 1px solid #eef1f4;
      thead {
        tr {
          background-color: #eef1f4;
          td {
            padding: 14px;
            color: #212529;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 14px;
            color: #212529;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.calendarBoxIcon {
  display: flex;
  justify-content: center;
  place-items: center;
  overflow: hidden;
}

/**************Week calendar CSS Start***********************/
.weekFullWidth {
  width: 100%;
  .weekBoxContainer {
    width: 100%;
  }
  .calenderDateCell,
  .analystCell {
    width: 14.25%;
    height: 50px;
    display: flex;
    place-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: '600px') {
.weekFullWidth{
  width: auto;
  .analystCell {
    min-width: 80px !important;
  }
}
}
/**************Week calendar CSS End*************************/

.monthCalendarList {
  display: block;
  width: 100%;
  padding: 0px 30px;
}

.monthCalendarSevenList {
  display: flex;
}

.monthCalendarBox {
  width: 14.27%;
  border: 1px solid #ced4da;
  height: 150px;
  padding: 16px 12px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
.noMonthBorder {
  border: 0px !important;
  cursor: auto;
}

.monthCalendarBoxHeight {
  height: 40px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding: 0px;
  cursor: default;
}

.mobScrollMonthCalendar {
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .labellingBox {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px;
    .calendarLabelling {
      // width: 35%;
      margin: 0px 0 12px 0px;
      justify-content: end;
    }
  }
  .customMonthCalendarFilterBox {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 1100px) {
  .mobScrollMonthCalendar {
    width: 100%;
    overflow: scroll;
  }
  .monthCalendarBox {
    min-width: 150px;
    height: 120px;
  }
  .monthCalendarBoxHeight {
    height: 40px;
  }
}

@media screen and (max-width: 380px) {
  .smButtonBox{
    button{
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

.greenTriangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: linear-gradient(
    135deg,
    #3ef26b,
    #28a745 30%,
    #1e7e34 60%,
    #145d27 80%,
    #0b3a14
  );
  clip-path: polygon(0 0, 100% 0, 0 100%);
  z-index: 0;
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  overflow: hidden; /* Make sure the shine effect is clipped */
}

.greenTriangle::before {
  content: '';
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.3) 30%,
    rgba(255, 255, 255, 0.1) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  clip-path: polygon(0 0, 100% 0, 0 100%);
  transform: rotate(-15deg);
  opacity: 0.7;
}

.shadowTriangle {
  position: absolute;
  top: 3px; /* Offset for shadow effect */
  left: 3px; /* Offset for shadow effect */
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.3); /* Shadow color */
  clip-path: polygon(0 0, 100% 0, 0 100%);
  z-index: 0; /* Below green triangle */
  filter: blur(2px); /* Optional: blur for a softer shadow */
}

.bookMarkContainerDiv {
  position: relative;
  // width: -webkit-fill-available;
  height: 58px;
}

.bookMarkContainerMonthDiv{
  height: 150px;
}

.shadowTrianglePositionTop{
  top: -14px;
  left: -9px;
}

.greenTrianglePositionTop{
  top: -18px;
  left: -12px;
}