.MainGrid {
  padding: 24px;
}

.RBACCardStyle {
  padding: 20px 24px;
  border-radius: 4px !important;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.cardContentStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
}

.TypographyStyle {
  margin-top: 25px;
}

.avatarHover {
  transition: all 0.5s linear;
}

.avatarHover:hover {
  transform: translateY(-6px);
  top: -6px;
  z-index: 1;
  position: relative;
}
