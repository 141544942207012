$primaryColor: #00a751;
$secondaryColor: #777777;
$peach: #fdf0ed;
$blackColor_light :rgba(255, 255, 255, 0.1);
$cyanBlueColor:  #212529;
$redColor: red;
$btnDanger: #F06548;
$backGroundColor_white: #ffff;
$white: #ffff;
$backGroundColor_cyanBlue: #EEF1F4;
$color_tealishBlue: #131523;
$backGroundColor_HARBOUR_FOG: #b0b1b3;
$backGroundColor_ROYCROFT_PEWTER: #606366;
$color_black: rgba(0,0,0,0.2);
$backGroundColor_MOURN_MOUNTAIN_SNOW: #E9EBEC;
$backGroundColor_DARK_ECLIPSE: #092245;
$color_SEARED_GREY: #495057;
$color_DUSTY_CHIMNEY: #878A99;
$border_color_CEREBRAL_GREY: #ccc;
$black: #000000;
$white: #ffffff;
$backGroundColor_WHITEOUT: #fbfbfb;
$color_Dusty_Chimney: #878A99;
$background-color-white-smoke: #f7f7f7;
$background-color-hawke-blue: #d0d9e4;
$background-color-cyan-blue: #CED4DA;