table tr td {
  font-size: 14px;
  // border: 1px solid black;
}
.mt10 {
  margin-top: 10px;
}
.p10 {
  padding: 10px !important;
}
.pt30{
  padding-top: 30px;
}
.pb10{
  padding-bottom: 10px;
}
.pl30{
  padding-left: 30px;
}
.pr30{
  padding-right: 30px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb30 {
  margin-bottom: 30px;
}
.mr30 {
  margin-right: 30px;
}
.mb50 {
  margin-bottom: 50px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}
.w8 {
  width: 8%;
}
.w10 {
  width: 10%;
}
.w13 {
  width: 13%;
}
.w6{
  width: 6%;
}
.w55{
  width: 55%;
}
.w17{
  width: 17%;
}

.w14{
  width: 14%;
}
.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}
.w48 {
  width: 48%;
}
.w26 {
  width: 26%;
}
.w50 {
  width: 50%;
}
.w55 {
  width: 55%;
}
.w70 {
  width: 75%;
}
.w90 {
  width: 90%;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}
.w40 {
  width: 40%;
}
.w60 {
  width: 60%;
}
.p10 {
  padding: 10px;
}
.p30 {
  padding: 30px;
}
.p20 {
  padding: 20px;
}
.p50 {
  padding: 50px;
}
.p70 {
  padding: 70px;
}
.h35 {
  height: 35px !important;
}
.pt40 {
  padding-top: 40px;
}
.pb50 {
  padding-bottom: 50px;
}
.pb30 {
  padding-bottom: 30px;
}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.vAlignTop {
  vertical-align: top;
}
.margin-auto {
  margin: 0 auto;
}
.logoTable td {
  vertical-align: top;
}
.logoContentTable td {
  height: 40px;
  padding-left: 5px;
}
.valignTopTable td{
  vertical-align: top;
}
.fs16{
  font-size: 16px;
}
.topUnderline {
  margin-top: 40px;
  text-decoration: overline;
  margin-left: 8px;
}
.thickLine {
  width: 100%;
  height: 5px;
}
.thinLine {
  margin-top: 2px;
  width: 100%;
  height: 1px;
}

.footerText {
  font-size: 14px;
  margin-top: -15px;
}

.mt20 {
  margin-top: 20px;
}

.commonTableBorderStyle {
  border: 10px solid #c0fbac;
  outline: 1px solid black;
  box-shadow: inset 0 0 0 1px black;
  position: relative;
}

.tableAfterBorderStyle{
  content: "";
  position: absolute;
  top: -12px;
  right: -20px;
  bottom: 0;
  width: 6px;
  height: calc(100% + 32px);
  border-radius: 2px;
}

.tableBeforeBorderStyle{
  content: "";
  position: absolute;
  left: -12px;
  right: 0;
  bottom: -20px;
  height: 6px;
  width: calc(100% + 32px);
  border-radius: 2px;
}
// .commonTableBorderStyle::after {
//   content: "";
//   position: absolute;
//   top: -12px;
//   right: -20px;
//   bottom: 0;
//   width: 6px;
//   height: calc(100% + 32px);
//   background-color: #4a73ff;
//   border-radius: 2px;
// }

// .commonTableBorderStyle::before {
//   content: "";
//   position: absolute;
//   left: -12px;
//   right: 0;
//   bottom: -20px;
//   height: 6px;
//   width: calc(100% + 32px);
//   background-color: #4a73ff;
//   border-radius: 2px;
// }

.tableContent {
  .tableContentList {
    width: 55%;
    margin: 0 auto;
    text-align: left;
  }
  p {
    max-width: 100%;
    margin-bottom: 15px;
  }
}
.copyright-text {
  font-size: 12px;
  margin-top: 20px;
}

.cylinder-container {
  display: flex;
  align-items: center;
  position: relative;
}

.cylinder-label-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.label {
  font-weight: bold;
  // color: #007bff;
}

.bay {
  position: relative;
  // width: 100px;
  height: 80px;
  background-color: white;
  border-top: 1px solid;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bay:last-child {
  border-radius: 0px 48px 48px 0px;
}

.bay .size {
  font-weight: bold;
  // color: #007bff;
}

.s-label {
  position: absolute;
  top: -26px;
  // color: #007bff;
  font-weight: bold;
  right: 10px;
}

.bay .content {
  text-align: center;
}

.arc {
  border: 3px solid;
  border-radius: 0 50% 50% 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 84px;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
}

.bay .left-arc {
  position: absolute;
  top: -1px;
  width: 18px;
  height: calc(100% + 2px);
  border: 2px solid;
  border-radius: 50%;
  z-index: 1;
  left: -10px;
  border-right: 2px solid;
  // background-color: #007bff;
}

table.bordered-table {
  position: relative;
}

table.bordered-table td {
  border: 1px solid #000000;
  height: 34px;
  padding: 0px 6px;
}

.tableAfterThickBorderStyle{
  content: "";
  position: absolute;
  top: 1px;
  right: -12px;
  bottom: 0;
  width: 8px;
  height: calc(100% + 9px);
  border-radius: 2px;
}
.tableBeforeThickBorderStyle{
  content: "";
  position: absolute;
  left: -1px;
  right: 0;
  bottom: -10px;
  height: 7px;
  width: calc(100% + 12px);
  border-radius: 2px;
}
// table.bordered-table::after {
//   content: "";
//   position: absolute;
//   top: 1px;
//   right: -12px;
//   bottom: 0;
//   width: 8px;
//   height: calc(100% + 9px);
//   background-color: #4a73ff;
//   border-radius: 2px;
// }
// table.bordered-table::before {
//   content: "";
//   position: absolute;
//   left: -1px;
//   right: 0;
//   bottom: -10px;
//   height: 7px;
//   width: calc(100% + 12px);
//   background-color: #4a73ff;
//   border-radius: 2px;
// }
.tableGreenHeading {
  // background-color: #c0fbac;
  color: #000000;
  font-size: 16px;
}
.boldText {
  font-weight: 600;
}

.vesselInfoHeader {
  display: flex;
  justify-content: space-between;
}

.underlineHeadingText {
  font-size: 20px;
  font-weight: 500;
  text-decoration: underline;
  padding-bottom: 20px;
}

.vesselInfoPageHeader {
  padding-top: 30px;
  display: block;
  width: 100%;
}

.pageHeader {
  width: 100%;
}

.topHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}
.bayLengthHeader {
  width: 100%;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  // margin-bottom: 10px;
}

.centerAlign {
  display: flex;
  justify-content: center;
}

.headingDescriptionText {
  text-align: left;
  font-weight: 600;
}

.boldHeading {
  font-size: 22px;
  font-weight: 600;
}

.subHeading {
  font-size: 18px;
  font-weight: 600;
}

.commonText {
  font-size: 16px;
  font-weight: 400;
}

.companyLogo {
  max-width: 320px;
  max-height: 200px;
}

.mxWidth400 {
  width: 47%;
  margin-right: 30px;
}

.mxWidth400:nth-child(even) {
  margin-right: 0px;
}

.vessel_info_data_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.fs14 {
  font-size: 12px;
  margin-bottom: 0px !important;
  margin-top: 4px !important;
}

.tubeBundleHeader {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.bigHeadingText {
  font-size: 32px;
  font-weight: 700;
}
.tubesNumberedArrow {
  padding: 20px 0 0 25px;
  border-top: 1px solid rgb(8, 183, 8);
  border-left: 1px solid rgb(8, 183, 8);
  position: relative;
  &::after {
    content: "";
    right: -50px;
    top: -14px;
    position: absolute;
    width: 70px;
    height: 29px;
    background-image: url(../../assets/right-arrow.png);
    background-size: 40%;
    background-repeat: no-repeat;
  }
  &::before {
    content: "";
    left: -37px;
    bottom: -22px;
    position: absolute;
    width: 70px;
    height: 29px;
    background-image: url(../../assets/right-arrow.png);
    background-size: 40%;
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }
}

.topVesselArrow {
  padding-top: 110px;
  position: relative;
  &::after {
    content: "";
    right: 13px;
    top: 0px;
    position: absolute;
    width: 70px;
    height: 29px;
    background-image: url(../../assets/right-arrow.png);
    background-size: 40%;
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
  &::before {
    content: "";
    left: 55px;
    bottom: 35px;
    position: absolute;
    width: 1px;
    height: 70px;
    background-color: rgb(8, 183, 8);
  }
}

.phaseChartImageContainer {
  width: 100%;
  margin: 25px auto 30px;
  text-align: center;
  border: 3px solid;
  padding: 20px 5px;
  max-height: calc(90vh - 320px);
  img{
    width: 100%;
    // height: auto;
    max-height: calc(90vh - 360px);
  }
}

.phaseChartCompassImage{
  width: 100%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  img{
    max-width: 250px;
    height: auto;
  }
}

.barChartContainer {
  width: 750px;
  // height: 53vh;
  height: 560px;
  display: flex;
  justify-content: center;
  border: 5px solid;
  place-items: center;
  border-radius: 30px;
  text-align: center;
  margin: 20px auto;
  padding: 20px;
}

.page-footer{
  font-size: 14px;
}

.ExpenseReportPDFDiv{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.legendContainer{
  width: 90%;
  // margin: 6px 6px 0 6px;
  margin: 0 auto;
  .legendItems{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    place-items: center;
    // margin-left: 400px;
    margin-top: 6px;
    .tubeChartLegend{
      border: 1px solid #000000;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 18px;
    }
  }
}

.noScrollWidth{
  ::-webkit-scrollbar {
    height: 0px !important;
    width: 0px !important;
  }
}

.customDataHtmlStyle{
  // all: unset;
  p{
    all: unset;
    width: 100% !important;
    // text-overflow: clip !important;
    white-space: normal !important;
    overflow: unset !important;
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important;
    display: block !important;
  }
}

.pageBorder950{
  height: 950px;
}

.pageBorder1000{
  height: 1000px;
}


.legendContainer {
  column-count: 2;
  column-gap: 20px; /* Adjust the gap between columns */
}

.legendItems {
  break-inside: avoid; /* Prevents splitting items across columns */
}


.expenseCashBox{
  display: flex;
  place-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
}

.expenseCashLeftBox{
  display: flex;
  place-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  border-right: 1px solid #000000;
}

.expenseCashRightBox{
  display: flex;
  place-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
}
