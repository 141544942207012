.flex_with_space_between {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.customer_header_div {
  display: flex;
  align-items: center;
}

.additional_charges_div {
  display: flex;
  justify-content: space-between;
}

.checkbox_style_div {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
