.companyInfoSection {
  margin-top: 30px;
  margin-bottom: 30px;
}

.section {
  padding: 12px;
}

.innerSection {
  padding: 16px 0px 0px;
}


.homepage {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.mainHeading{
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  padding: 12px 0px 0px;
}

.heading {
  color: #333;
  font-size: 24px;
  margin-bottom: 5px;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 12px;
  border-bottom: 1px solid #CCC;
}


.billingSection {
  margin: 20px 0;
}

.innerSection {
  margin-bottom: 10px;
}
.pageBreak {
  page-break-before: always;
  break-before: page;
}

.innerSection label {
  font-weight: bold;
}

.contractorDetailsSection {
  margin: 20px 0;
}

.primaryContactSection {
  margin: 20px 0;
}
