.tablecell_div_style {
  display: flex;
  align-items: center;
}

.select_style {
  background-color: white !important;
  height: 28px !important;
}

.vessel_button_style {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.total_tubes_style {
  background-color: #E9EBEC;
  max-width: 88px;
  font-weight: 500 !important;
}

.total_tube_test {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 400px;
}

.tube_text {
  margin-right: 5px;
  font-weight: 500;
}

.total_tube_card {
    margin: 20px 0px;
}

.AddPlaylistStyle {
    margin-top: 35px;
}

.tablecell_div_style{
  display: flex;
  align-items: center; 
}

.manualVessel {
  width: 160px;
  background-color: white;
  div {
    height: 30px !important;
  }
}