@import '../../styles/variable';

.wd-5{
  width: 10.5%;
}
.wd-45{
  width: 44.5%;
}

.CustomTableBorderedContainer{
  padding: 30px 36px;
  border: 1px solid #00A751;
  border-radius: 4px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
  height: 100%;
}

.allCentered{
  display: flex;
  justify-content: center;
  place-items: center;
}

.oilReportTableContainer{
  margin-top: 16px;
  .oilReportTableHead{
    display: flex;
    justify-content: space-between;
    .headerTableCell{
      background-color: rgb(229, 246, 237);
      padding: 14px;
      position: relative;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #212529;
      svg{
        margin-left: 4px;
        margin-bottom: 1px;
        cursor: pointer;
      }
    }
  }
  .oilReportTableBody{
    .oilReportTableList{
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
      &:last-child{
        margin-bottom: 0px;
      }
      .oilReportTableCell{
        border: 1px solid #E9EBEC;
      }
    }
  }
}

.oilReportTableBody{
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  .oilReportTableCell{
    padding: 14px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #495057;
    display: flex;
    justify-content: space-between;
    place-items: center;
  }
}

.actionBtn{
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background-color: #D9D9D9;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  place-items: center;
  cursor: pointer;
}

.noBorder{
  border: none !important;
}

.actionButtons{
  display: flex;
  .actionBtn{
    margin-right: 8px;
    &:last-child{
      margin-right: 0px;
    }
  }
}


.modalModalHeading{
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 800;
  font-size: 25px;
  color: $color_purple;
  font-family: $primaryFont;
}