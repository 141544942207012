@import "../themes/colors.scss";
@import "./globalmixin.scss";
@import "./_breakpoint.scss";
@import "./variable";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: $primaryFont !important;
}

input {
  text-transform: capitalize;
}

.nonCapitalizeField input {
  text-transform: none !important;
}

.nonCapitalizeInput {
  text-transform: none !important;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

.width-100 {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr6 {
  margin-right: 6px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-100 {
  margin-right: 100px;
}
.pointer {
  cursor: pointer;
}

.p-0 {
  padding: 0px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.customMarginAccordion {
  margin: 0px 15px !important;
}

.dataTableCustomMargin {
  margin: 0 auto !important;
  padding-left: 30px !important;
  padding-right: 16px !important;
  width: 100% !important;
}

.customDataTableStyles {
  padding-left: 16px !important;
  @media screen and (max-width: 500px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.accordionHeading {
  background-color: #ffffff !important;
  border-radius: 6px !important;
}

.commonCenter {
  display: flex !important;
  justify-content: center !important;
  place-items: center !important;
  flex-direction: row !important;
  .label_Style_job {
    margin-bottom: 0px;
    margin-right: 8px;
  }
}

.morePadding {
  padding: 9px 0px;
}

.customAccordionHeader {
  display: flex;
  justify-content: space-between;
  place-items: center;
  width: 100%;
  .leftSideHeading {
    display: flex;
    width: fit-content;
    place-items: baseline;
  }
}

.borderTop {
  border-top: 1px solid #e9ebec;
}

.MuiAccordion-root {
  box-shadow: none !important;
  border: 1px solid #e9ebec !important;
  border-radius: 6px !important;
}

.login_page_left_sec {
  background: $primaryColor;
  height: 100vh;
  position: relative;

  .rotateBgImage {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translate(0%, -50%);
    width: 45%;
    height: auto;
    .rotateCenter {
      -webkit-animation: rotate-center 20s linear infinite reverse forwards;
      animation: rotate-center 20s linear infinite reverse forwards;
      width: 100%;
      height: auto;
      @media screen and (min-width: $tablet) and (max-width: $laptop) {
        width: 80%;
      }
    }
  }
  .loginBannerImage {
    position: absolute;
    bottom: 0%;
    left: 12%;
    transform: translate(0%, 0%);
  }
  .loginBannerTestMenu {
    position: absolute;
    right: 3%;
    top: 50%;
    transform: translate(0%, -50%);
    display: block;
    text-align: -webkit-right;
    .testMenuContainer {
      height: 64px;
      width: fit-content;
      background-color: #d9d9d9;
      border: 1px solid #e9ebec;
      border-radius: 4px;
      box-shadow: 1px 0px 10px 3px #00000040;
      display: flex;
      justify-content: center;
      place-items: center;
      padding: 0 18px;
      font-weight: 700;
      font-size: 13px;
      margin-bottom: 80px;
      animation: up-down 8s linear infinite alternate;
      &:last-child {
        margin-bottom: 0px;
      }
      .testMenuImage {
        width: 44px;
        height: 44px;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: -1px 1px 7px 2px #00000040;
        display: flex;
        justify-content: center;
        place-items: center;
        overflow: hidden;
        margin-right: 10px;
        padding: 7px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .oppositeMotion {
      animation: up-down-opposite 8s linear infinite alternate;
    }
  }
  @media (max-width: 900px) {
    display: none !important;
  }
}

@media (min-width: $mobile) {
  .ps-submenu-expand-icon {
    span {
      display: inline-block !important;
      -webkit-transition: -webkit-transform 0.3s !important;
      transition: transform 0.3s !important;
      border-right: 2px solid currentcolor !important;
      border-bottom: 2px solid currentcolor !important;
      width: 5px !important;
      height: 5px !important;
      -webkit-transform: rotate(-45deg) !important;
      -moz-transform: rotate(-45deg) !important;
      -ms-transform: rotate(-45deg) !important;
      transform: rotate(-45deg) !important;
      background-color: transparent;
      border-radius: 0 !important;
    }
  }

  .ps-submenu-expand-icon.ps-open {
    span {
      -webkit-transform: rotate(45deg) !important;
      -moz-transform: rotate(45deg) !important;
      -ms-transform: rotate(45deg) !important;
      transform: rotate(45deg) !important;
    }
  }
}
.login_page_right_sec {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $backGroundColor_WHITEOUT;

  .login_form_sec {
    width: 100%;
    max-width: 500px;
    padding: 20px;

    .logo {
      text-align: center;

      img {
        max-width: 269px;
      }
    }

    .sign_in_heading {
      font-size: 26px;
      font-weight: 700;
      color: $black;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

.form_group {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;

  .form_label {
    width: 100%;
    font-size: 18px;
    font-weight: 500;

    span {
      color: $redColor;
      font-weight: bold;
    }
  }

  .input_box_style {
    width: 100%;
    padding: 12px 14px;
    border-radius: 6px;
    border: 1px solid $border_color_CEREBRAL_GREY;
    outline-width: 0;
    margin-top: 5px;
  }

  .link_style {
    color: $primaryColor;
    font-weight: 500;
    margin-top: 8px;
    display: inline-block;
    text-decoration: none;
  }
}

.login_btn {
  background: $primaryColor;
  height: 55px;
  border: none;
  outline-width: 0;
  text-align: center;
  width: 100%;
  border-radius: 6px;
  color: $white;
  font-size: 16px;
  margin-top: 20px;
}

// Job Starter and Bid Worksheet for Job

.job_input_div {
  margin: 0px 8px;
}

.job_input_style {
  height: 29px;
  font-size: 12px;
  padding: 7px 12px;
  color: $color_Dusty_Chimney;
  border: 1px solid $backGroundColor_MOURN_MOUNTAIN_SNOW;
  display: flex;
  justify-content: center;
  place-items: center;
}

.bid_job_input_style {
  width: 101px;
  height: 27px;
  margin-left: auto;
  font-size: 12px;
  border-radius: 4px;
  padding: 7px 14px;
  color: $color_Dusty_Chimney;
  border: 1px solid $backGroundColor_MOURN_MOUNTAIN_SNOW;
  &::before {
    border-bottom: none !important;
  }
}

.job_tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.job_button_style {
  font-weight: 500;
  font-size: 14px;
  color: $backGroundColor_white !important;
  background: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  border-radius: 4px !important;
}

.container_job {
  padding: 15px 16px 10px 16px;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: space-between;
}

.div_container_CustomerInfo {
  background: #f3f3f9;
}

.customerInfo {
  font-weight: 600;
  font-size: 15px;
  color: #212529;
  font-family: $primaryFont !important;
}

.timerInput {
  div{
  height: 40px !important  ;
  }
}

.label_Style_job {
  font-weight: 500;
  font-size: 13px;
  color: #212529;
  margin-bottom: 9px;
}

.label_Style_disabled {
  font-weight: 500;
  font-size: 13px;
  color: #878a99;
  margin-bottom: 9px;
}

.contact_info_text {
  font-weight: 600;
  font-size: 15px;
  padding: 15px 0px;
  border-top: 1px solid #e9ebec;
  border-bottom: 1px solid #e9ebec;
}

.button_save_and_next {
  background-color: $primaryColor !important;
  color: $backGroundColor_white !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 9px 18px !important;
  text-transform: capitalize !important;
}

.button_cancel {
  background-color: $background-color-cyan-blue !important;
  color: $cyanBlueColor !important;
  font-size: 13px !important;
  font-weight: 500;
  padding: 9px 18px !important;
  text-transform: capitalize !important;
}

.button_margin_left {
  margin-left: 16px;
}

.Mui-selected.tab_styles {
  color: #fff !important;
  border: 1px solid $primaryColor !important;
}

.tab_styles {
  color: #878a99 !important;
  border: 1px solid #ced4da !important;
  padding-right: 25px !important;
  padding-left: 25px !important;

  @media screen and (max-width: 568px) {
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: auto !important;
    min-height: auto !important;
  }
}

// 1065px

.save_and_next_div {
  @include display_items(flex, flex-end, "");
}

@media screen and (max-width: 450px) {
  .small_mob_button_container{
    flex-direction: column;
    margin-bottom: 0px !important;
    .button_margin_left {
      margin-left: 0px;
      width: -webkit-fill-available;
      &:first-child{
        margin-bottom: 10px;
      }
      button{
        width: -webkit-fill-available;
      }
  }
  }
}

.container_job_search {
  @include display_items(flex, center, center);

  @media screen and (max-width: 568px) {
    margin-top: -8px;
  }
}

.div_job_container_info {
  padding: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 182px);
  @media screen and (max-width: $mobile) {
    padding: 10px 15px;
    max-height: calc(100vh - 163px);
  }
}

.div_themes_container {
  padding: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 148px);
  @media screen and (max-width: $mobile) {
    padding: 10px 15px;
  }
}

.customBodyWithoutBtnContainer {
  max-height: calc(100vh - 140px);
}

.div_job_container_info::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.div_job_container_info::-webkit-scrollbar-track {
  background: $background-color-white-smoke;
}

.div_job_container_info::-webkit-scrollbar-thumb {
  background: $background-color-hawke-blue;
  border-radius: 10px;
}

.Bid_pervious {
  padding: 15px 20px;
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  border-bottom: 1px solid #e9ebec;
}

.add_bid_div {
  padding: 15px 20px;
  border-bottom: 1px solid #e9ebec;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customerInfo_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ebec;
  padding: 15px 20px;
  color: $cyanBlueColor;

  @media screen and (max-width: $mobile) {
    padding: 5px 10px;
    flex-direction: column;
    place-items: flex-start;
  }

  label span {
    font-size: 12px !important;
    color: $color_DUSTY_CHIMNEY;
    font-weight: 500;
    line-height: normal;
  }
}

.themes_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ebec;
  padding: 15px 20px 15px 0px;
  color: $cyanBlueColor;

  @media screen and (max-width: $mobile) {
    padding: 5px 10px 5px 0px;
    flex-direction: column;
    place-items: flex-start;
  }

  label span {
    font-size: 12px !important;
    color: $color_DUSTY_CHIMNEY;
    font-weight: 500;
    line-height: normal;
  }
}

.double_checkbox_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #e9ebec;
  padding: 15px 20px;
  color: $cyanBlueColor;

  @media screen and (max-width: $mobile) {
    padding: 10px;
    flex-wrap: wrap;
    padding: 15px 0 0 15px;
    justify-content: flex-start;
    .container_checkmark_div {
      display: flex;
      margin-right: 10px;
      height: 29px;
      flex-wrap: wrap;
      margin-bottom: 10px;
      flex-direction: row;
      width: max-content;
    }
  }
}

.double_checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: $mobile) {
    padding: 10px 0px;
    justify-content: flex-start;
    .container_checkmark_div{
      margin-bottom: 10px;
    }
  }
}

.customerInfo_main_container {
  padding: 16px;

  @media screen and (max-width: $mobile) {
    padding: 10px;
    position: relative;
  }
}

.unitDetails_main_container {
  padding: 16px 20px;
  height: 1065px;
}

.unit_info_main_container {
  padding: 16px 20px;
  height: auto;
}

.paperStyle {
  width: 100% !important;
}

.customerID {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 0px 8px;
  color: $color_DUSTY_CHIMNEY;
}

.select_style {
  width: 150px;
  height: 28px;
  font-size: 14px !important;
  color: #495057 !important;
}

.special_location_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ebec;
  padding: 17px 20px;
}

.typography_Accordion {
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #212529 !important;
  font-family: $primaryFont !important;
}

.app {
  background: #ffff;
}

.section_Style {
  background-color: #f3f3f9;
}

.radio_button_style {
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 16px;
  color: #2d3136;
}

.checkbox_last_container {
  display: flex;
  align-items: center;
}

.checkbox_container_style {
  @extend .checkbox_last_container;
  padding-right: 30px;
}

.editorContainer {
  border-radius: 6px;
  height: 308px;
  border: 1px solid #e9ebec;
  padding: 0px 10px;
  @media screen and (max-width: 630px) {
    height: 328px;
  }
  @media screen and (max-width: $mobile) {
    height: 308px;
  }
  @media screen and (max-width: 365px) {
    height: 325px;
  }
}

.editContainerWithEditBtn {
  position: relative;
  @media screen and (max-width: $mobile) {
    height: 358px;
  }
  @media screen and (max-width: 365px) {
    height: 375px;
  }
}

.demo-editor {
  max-height: 238px;
  background-color: #f3f3f9;
  min-height: 238px;
  padding: 16px;
  border-radius: 4px;
  @media screen and (max-width: 372px) {
    min-height: 235px;
  }
  @media screen and (max-width: 365px) and (min-width: 355px) {
    min-height: 252px;
  }
}

.rdw-inline-wrapper,
.rdw-list-wrapper,
.rdw-block-wrapper {
  margin-bottom: 0px;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  border: none !important;
  &:hover {
    box-shadow: none !important;
  }
}

.editorBtn {
  position: absolute;
  right: 12px;
  top: 7px;
  @media screen and (max-width: $mobile) {
    position: unset;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.rdw-editor-toolbar {
  border: none !important;
  border-bottom: 1px solid #e9ebec !important;
  padding: 12px 0px;
}

.demo-editor::-webkit-scrollbar {
  height: 10px;
  width: 8px;
}

.demo-editor::-webkit-scrollbar-track {
  background: $background-color-white-smoke;
}

.demo-editor::-webkit-scrollbar-thumb {
  background: $background-color-hawke-blue;
  border-radius: 10px;
}

.checkBox-style {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dialogTitle_style {
  border-bottom: 1px solid #e9ebec;
}

.dialogTitle_style_div {
  display: flex;
  justify-content: space-between;
}

.mob_dialogTitle_style_div{
  @media screen and (max-width: 500px) {
    flex-direction: column;
    .container_checkmark_div{
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .mr-12{
      margin-right: 0px;
    }
  }
}

.dialogContent_style {
  padding: 0px !important;
}

.checkbox_padding {
  padding: 0px !important;
}

.dialogContent_style_div {
  padding: 20px;
}

.card_style {
  min-height: 400px;
}

.div_button_group {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.button_edit_schedule {
  @extend .button_save_and_next;
  margin-left: 16px !important;
}

.table_cell {
  @extend .checkbox_last_container;
}

.table_checkbox_style {
  margin-left: 5px;
}

.Card_div {
  @extend .Bid_pervious;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div_container_trip {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.div_inner_trip {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
  justify-content: space-between;
  margin-bottom: 8px;
}

.textfield_div_trip {
  width: 94%;

  div {
    font-size: 13px;
    padding-bottom: 2px;
  }
}

.div_button {
  padding: 0px 0px 5px 5px;
}

.button_style_trip {
  border: 0px;
  background-color: white;
}

.errorMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $color_red;
  font-size: 12px;
  padding: 4px 0px;
}

.successLogo {
  margin: 30px 0px 10px 0px;
}

input {
  font-size: 13px !important;
  font-weight: 500 !important;
  height: 24px !important;
}

.required_maark {
  color: red;
}

.search_icon_btn {
  position: absolute !important;
  right: 0;

  &::after {
    content: "";
    width: 1px;
    height: 22px;
    background-color: #c8c8cd;
    position: absolute;
    left: 0;
  }
}

.profileLoader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.popup_cancel_btn {
  padding: 10px 18px;
  border: none;
  background: #ced4da;
  border-radius: 4px;
  font-size: 13px;
  color: #212529;
  font-weight: 500;
}

.popup_submit_btn {
  padding: 10px 18px;
  border: none;
  background: #00a751;
  border-radius: 4px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  margin-left: 16px;
}

.modal_body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal_popup {
  background-color: #fff;
  border-radius: 6px;
  padding: 0px;
  min-width: 700px;

  .modal_heading {
    padding: 15px 24px;
    position: relative;
    border-bottom: 1px solid #e9ebec;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;

    .heading {
      font-family: $primaryFont;
      font-size: 15px;
      font-weight: 600;
      color: #212529;
    }

    .close_modal {
      font-size: 15px;
      cursor: pointer;
      width: 30px;
      text-align: center;
      margin-right: -10px;
    }
  }

  .modal_footer {
    padding: 15px 24px 22px 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;

    .footer_button_sec {
      position: relative;
    }
  }

  &.md {
    min-width: 900px;
  }

  &.lg {
    min-width: 1100px;
  }

  .table-pagination-style {
    padding: 0;
    margin-top: 15px;
  }
}

.whiteSpace {
  height: 57px;
  background-color: white;
}

.blur-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);
  z-index: -1;
  background: rgba(255, 255, 255, 0.14);
  // background: rgba(218, 69, 69, 0.82);
}

.blur-image {
  position: relative;
  z-index: 1;
}

.required_maark {
  color: red;
}

.routeManger_main {
  position: relative;
}

.loader_styles {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(5px);
}

.MuiSelect-select {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 400;
  font-size: 13px;
}

.MuiMenuItem-root {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.customer_info_checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  label span {
    font-size: 12px !important;
    color: $color_DUSTY_CHIMNEY;
    font-weight: 500;
    line-height: normal;
  }
}

.dropdown_side_label {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding: 0px 8px;
  color: #212529;
}

.wrongOPT {
  justify-content: center;
  align-items: center;
  color: $color_red;
  font-size: 12px;
  font-weight: 500;
}

.datePicker_style {
  input {
    height: 24px !important;
  }
}

.rangeDatePicker {
  position: relative;
  z-index: 0;
}

.copy_request_button {
  padding: 20px;
  display: flex;
  margin: 0px 15px 15px 15px;
  justify-content: flex-end;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.unit_test_style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.vessel_button_style {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.margin_3 {
  margin-left: 3px;
}

@media screen and (max-width: $mobile) {
  .mob-w-100{
    width: 100%;
  }
  .mob_pd-0 {
    padding: 0;
  }

  .customerInfo_container {
    .customerInfo {
      margin-top: 8px;
      margin-bottom: 12px;
      width: 100%;
      .MuiFormControl-root{
        width: 100%;
      }
    }

    .customer_info_checkbox {
      padding: 12px 0px !important;
      border-top: 1px solid $color_bottom;
      flex-direction: column;
      place-items: flex-start;
      width: 100%;

      .customer_info_header_checkbox {
        height: 50px;
        justify-content: space-between;
        width: 100%;

        .container_checkmark_div {
          flex-direction: column;

          .container_label {
            margin-bottom: 8px;
          }
        }
      }

      @media screen and (max-width: $mobile) {
        .mob_mr_12{
          margin-bottom: 12px;
        }
        .mobileInfoBox{
          height: 56px;
        }
        .dropdown_side_label{
          padding: 0;
          margin-top: 10px;
          margin-bottom: 6px;
        }
      }

      .dropdown_container {
        place-items: flex-start;
        flex-direction: column;
        // margin-top: 20px;
        padding-top: 20px;

        .dropdown_side_label {
          padding: 0;
          margin-bottom: 8px;
        }
      }

      .mob-pt-3 {
        padding-top: 45px;
      }
    }
  }

  @media screen and (max-width: '600px') {
      .mob_pt_0 {
        padding-top: 0px !important;
      }
  }

  .div_checkbox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;

    .container_checkmark_div {
      width: 50%;
      flex-direction: column;
      margin-right: 0px;
      height: 62px;

      .container_label {
        margin-bottom: 5px;
      }
    }
  }

  .mobStyleCheckbox{
    .container_checkmark_div{
      margin-bottom: 16px;
    }
  }

  .mob-header-inline {
    flex-direction: row;
  }

  .table-pagination-style {
    flex-direction: column;
    place-items: center;
    .table-pagination-number-per-page {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .table-pagination-number {
      margin-bottom: 10px;
    }
  }

  .mob-pdfMailSystem{
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    overflow-x: hidden;
	}
}

::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important;
}

::-webkit-scrollbar-thumb {
  background: $background-color-hawke-blue !important;
  border-radius: 10px !important;
}

.table-pagination-number {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  place-items: center;
  .pagination,
  button {
    margin-top: 0px !important;
  }
  @media screen and (max-width: $miniMobile) {
    flex-direction: column !important;
  }
  button {
    height: 34px;
  }
  ul {
    li {
      button {
        margin: 0px;
        border-radius: 0px;
        font-size: 13px;
      }
    }
  }
  .previousBtnPagination {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.23);
    color: $color_purple;
    font-size: 13px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
    @media screen and (max-width: $miniMobile) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 8px;
    }
  }
  .nextBtnPagination {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.23);
    color: $color_purple;
    font-size: 13px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
    @media screen and (max-width: $miniMobile) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-top: 8px !important;
    }
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: $color_purple;
    color: $color_white;
  }
}

.inline_checkbox_in_a_row {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .container_checkmark_div {
    padding-left: 15px;
    position: relative;
    padding-right: 15px;
    margin-right: 0;
    &:last-child {
      padding-right: 0px;
    }

    @media screen and (max-width: $mobile) {
      margin-left: 0;
      width: auto;
      padding-bottom: 15px;
      margin-bottom: 10px;
      padding-top: 6px;
      border-radius: 5px;
      margin-right: 10px;
      padding-left: 6px;
      padding-right: 0;
    }

    @media screen and (max-width: $mobile) {
      &::after {
        display: none;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 0;
    background: none;
    border: none;
  }
}

.label_Disable {
  font-weight: 500;
  font-size: 13px;
  color: #878a99;
  margin-bottom: 9px;
}

.save_and_next_div {
  @include display_items(flex, flex-end, "");
  @media screen and (max-width: $mobile) {
    margin-bottom: 40px;
  }
}

.button_cancel {
  background-color: $background-color-cyan-blue !important;
  color: $cyanBlueColor !important;
  font-size: 13px !important;
  font-weight: 500;
  // width: 80px;
  height: 40px;
  text-transform: capitalize !important;
}

.button_margin_left {
  margin-left: 16px;
}

.button_save_and_next {
  background-color: $primaryColor !important;
  color: $backGroundColor_white !important;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  text-transform: capitalize !important;
}

.button_save_and_next:disabled {
  opacity: 0.7;
  background-color: gray !important;
  color: #fff !important;
}

.billingInfo_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ebec;
  padding: 15px 20px;
  color: $cyanBlueColor;
}

.billingInfo {
  font-weight: 600;
  font-size: 15px;
  color: $cyanBlueColor;
}

.billingInfo_main_container {
  padding: 16px 20px;
}

.div_label_text {
  display: flex;
  flex-direction: column;
}

.label_Style_Customer {
  font-weight: 500;
  font-size: 13px;
  color: $cyanBlueColor;
  margin-bottom: 9px;
}

.billing_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ebec;
  padding: 15px 20px;
  color: $cyanBlueColor;
}

.billing {
  font-weight: 600;
  font-size: 15px;
  color: $cyanBlueColor;
}

.billing_main_container {
  padding: 16px 20px;
}

.div_label_text {
  display: flex;
  flex-direction: column;
}

.label_Style_Customer {
  font-weight: 500;
  font-size: 13px;
  color: $cyanBlueColor;
  margin-bottom: 9px;
}

.KitsAssigned_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ebec;
  padding: 15px 20px;
  color: $cyanBlueColor;
}

.KitsAssigned {
  font-weight: 600;
  font-size: 15px;
  color: $cyanBlueColor;
}

.KitsAssigned_main_container {
  display: flex;
  padding: 16px 20px;
}

.div_label_text {
  display: flex;
  flex-direction: column;
}

.div_label {
  display: flex;
  flex-direction: row;
}

.label_Style_Customer {
  font-weight: 500;
  font-size: 13px;
  color: $cyanBlueColor;
  margin-bottom: 9px;
}

.to_label {
  margin-top: 9px;
  font-weight: 500;
  font-size: 13px;
  color: $cyanBlueColor;
}

.add_kits_icon {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
  margin-left: 15px;
  cursor: pointer;
}

.viewOilKitsComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5px 15px 20px;
  border-bottom: 1px solid #e9ebec;
}

.viewOilKitsComponent_crossIcon {
  cursor: pointer;
}

.viewOilKitsComponent_heading {
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  color: #212529;
}

.viewOilKitsComponent_dataTableStyle {
  padding: 0px 20px;
}

.viewOilKitsComponent_saveAndNextDiv {
  padding: 11px 10px;
  @include display_items(flex, flex-end, "");
}

.viewOilKitsComponent_buttonCancel {
  background-color: $background-color-cyan-blue !important;
  color: $cyanBlueColor !important;
  font-size: 13px !important;
  font-weight: 500;
  height: 40px;
  text-transform: capitalize !important;
}

.viewOilKitsComponent_buttonMarginLeft {
  margin-left: 16px;
}

.viewOilKitsComponent_buttonDelete {
  background-color: $btnDanger !important;
  color: $backGroundColor_white !important;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  text-transform: capitalize !important;
}

.viewOilKitsComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px 15px 20px;
  border-bottom: 1px solid #e9ebec;
}

.viewOilKitsComponent_crossIcon {
  cursor: pointer;
}

.viewOilKitsComponent_dataTableStyle {
  padding: 0px 0px;
}

.viewOilKitsComponent_saveAndNextDiv {
  padding: 11px 10px;
  @include display_items(flex, flex-end, "");
}

.viewOilKitsComponent_buttonCancel {
  background-color: $background-color-cyan-blue !important;
  color: $cyanBlueColor !important;
  font-size: 13px !important;
  font-weight: 500;
  height: 40px;
  text-transform: capitalize !important;
}

.viewOilKitsComponent_buttonMarginLeft {
  margin-left: 16px;
}

.viewOilKitsComponent_buttonDelete {
  background-color: $btnDanger !important;
  color: $backGroundColor_white !important;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  text-transform: capitalize !important;
}

.addBtn {
  background-color: $primaryColor !important;
  color: $backGroundColor_white !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  padding: 6px 30px;
  white-space: nowrap;
  min-width: auto;
}

.tableCell-edit {
  display: flex;
  justify-content: flex-start;
}

.disabledButtonStyle {
  button:disabled,
  button[disabled] {
    color: #fff !important;
  }
}

.customModalBtnContainer {
  border-bottom: 1px solid #e9ebec;
  padding: 13px 20px;
  @media screen and (max-width: $mobile) {
    padding: 8px 8px 1px;
  }
}

.tableRadioBox {
  padding: 0px !important;
}

.tableWithExtraTab {
  max-height: 31vh !important;
}

.customerInfoHeaderFilter {
  display: flex;
  width: 100px;
  justify-content: flex-start;
  place-items: center;
  margin-top: 0px;
  @media screen and (max-width: $tablet) {
    margin-top: -8px;
  }
}

.customModalScroll {
  max-height: calc(90vh - 75px);
  margin-bottom: 0px;
}

.customModalScroll2 {
  overflow-y: auto;
  overflow-x: hidden;
}

.tableBody {
  overflow-y: auto;
  max-height: calc(100vh - 345px) !important;
  @media screen and (max-width: $tablet) {
    max-height: calc(100vh - 430px) !important;
  }
  @media screen and (max-width: "422px") {
    max-height: calc(100vh - 450px) !important;
  }
}

.recommendationsTableStyle {
  overflow-y: auto;
  max-height: calc(73vh - 345px) !important;
  @media screen and (max-width: $tablet) {
    max-height: calc(100vh - 430px) !important;
  }
  @media screen and (max-width: "422px") {
    max-height: calc(100vh - 450px) !important;
  }
}

.footer_button_sec {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: $tablet) {
  .mob_m_0 {
    margin: 0px !important;
  }
  .mob_mb_0 {
    margin-bottom: 0px !important;
  }
  .mob_place_center {
    place-items: center !important;
  }
  .mob_fit_content {
    max-height: fit-content !important;
  }
}

.modalHeaderTitle {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: $labelColor;
  font-family: $primaryFont !important;
  border-bottom: 1px solid $color_bottom;
}

.leftBtn {
  margin-right: 12px !important;
}

.asignedKits {
  display: flex;
  justify-content: flex-start;
  input {
    border-radius: 4px;
    border: 1px solid #00000038;
    padding: 8.5px 14px;
    height: 40px !important;
  }
  .inputAssignedKits {
    width: 45%;
  }
  .textTo {
    width: 10%;
    justify-content: center;
    place-items: center;
    span {
      font-size: 13px;
      font-weight: 500;
      color: #212529;
    }
  }
  @media screen and (max-width: $tablet) {
    display: block;
    flex-direction: column;
    .inputAssignedKits,
    .textTo {
      width: 95%;
    }
  }
}
.addMoreBtn {
  display: flex;
}
.addAssignedBtnContainer {
  display: flex;
  justify-content: flex-end;
  place-items: flex-end;
}

@media screen and (max-width: $tablet) {
  .addMoreBtn {
    padding-left: 0px !important;
  }
  .add_kits_icon {
    margin-left: 8px;
  }
}

/**********customDateRangeCalender********/
.customDateRangeCalender {
  width: 100%;
  span.rs-picker-toggle-placeholder {
    font-size: 13px !important;
  }
  input {
    height: 100% !important;
    font-size: 13px !important;
  }
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #00a751;
}

.rs-stack-item {
  width: fit-content;
  button {
    font-weight: 600;
  }
  .rs-btn-link {
    color: #212529;
    text-decoration: none;
    width: 100%;
    border-radius: 0px;
    text-align: left;
    &:hover {
      background-color: #00a751;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.rs-picker-daterange-predefined {
  .rs-stack-item {
    width: 100%;
  }
}

.rs-picker-menu {
  text-align: right;
  button.rs-btn-primary {
    background-color: #00a751 !important;
  }
}

.rs-calendar-table-header-cell {
  span {
    font-weight: 600;
    color: #000000;
  }
}
.rs-calendar-header-month-toolbar {
  button {
    font-weight: 600;
    color: #000000;
  }
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #00a751;
}

.rs-calendar-table-cell .rs-calendar-table-cell-content:hover {
  background-color: #00a75115 !important;
  border-radius: 7px !important;
  color: #212529 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #212529;
}
.rs-picker-toggle:focus-visible {
  outline: none !important;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus {
  border-color: #e5e5ea !important;
}
@media screen and (max-width: 768px) {
  .rs-picker-daterange-calendar-group {
    display: flex;
    flex-direction: column;
    height: auto;
    min-width: fit-content;
    .rs-calendar {
      width: fit-content;
      min-width: 100%;
      margin: 0;
    }
  }

  .rs-picker-daterange-panel {
    .rs-stack {
      flex-direction: column;
      .rs-stack-item {
        width: 100% !important;
        .rs-picker-daterange-predefined {
          flex-direction: row !important;
          height: auto;
          overflow: scroll;
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .rs-picker-daterange-menu {
    width: 75%;
  }
}
/**********customDateRangeCalender********/

td p {
  max-width: 250px;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  background: #fff;
  margin: 0px;
  word-break: break-all;
}

.changePasswordBanner {
  background-color: #00a751;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: auto;
    @media screen and (max-width: $tablet) {
      width: 90%;
    }
    @media screen and (max-width: $mobile) {
      width: 70%;
    }
  }

  .changePaswordBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    width: 52%;
    height: auto;
    @media screen and (max-width: $laptop) {
      width: 75%;
    }
    .rotateCenter {
      -webkit-animation: rotate-center 20s linear infinite reverse forwards;
      animation: rotate-center 20s linear infinite reverse forwards;
      width: 100%;
      height: auto;
    }
  }
  .changePaswordImage {
    position: absolute;
    bottom: 0%;
    left: 65%;
    transform: translate(-50%, 0%);
  }
  @media screen and (max-width: $desktop) {
    display: none;
  }
}

.girlImageChangePassword {
  width: 100%;
  height: auto;
}

/***********************************************/
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes up-down-opposite {
  0% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes up-down-opposite {
  0% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
/**********************************************/

.changePasswordContainer {
  height: auto;
  display: flex;
  justify-content: center;
  place-items: center;
  flex-direction: column;
  padding: 60px 40px;
  border-radius: 0px;
  margin: 50px 75px;
  border-style: double;
  border-color: #00a751;
  position: relative;
  .lockIconImage {
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  @media screen and (max-width: $tablet) {
    margin: 70px 12px;
    padding: 40px 10px;
    background-color: #ffffff;
  }
  .changePasswordHeader {
    color: #092245;
    font-size: 25px;
    font-weight: 600;
    line-height: 33.6px;
    margin-bottom: 40px;
    @media screen and (max-width: $tablet) {
      margin-top: 40px;
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
  .label_Style_Customer {
    font-size: 13px;
    font-weight: 500;
    color: #212529;
  }
}

.errorMsg {
  color: #d32f2f;
  font-size: 13px;
  margin: 0px 0px 20px 12px;
  @media screen and (max-width: $tablet) {
    margin: 0px 0px 10px 12px;
  }
}
/*4. MenuItemAnimation.css */

@keyframes flipInAndOut {
  0% {
    opacity: 0;
    transform: rotateY(-40deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

.menu-item-enter {
  animation: flipInAndOut 600ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.menu-item-exit {
  animation: flipInAndOut 600ms cubic-bezier(0.68, -0.55, 0.27, 1.55) reverse;
}

.tooltipMainTab {
  border-radius: 4px;
  padding: 5px 12px;
  background: rgba(0, 167, 81, 0.1);
  text-align: center;
  color: #00a751;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  width: fit-content;
}

.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  border: 1px solid #00a751 !important;
  font-weight: 500 !important;
  color: #000000 !important;
  padding: 12px 15px !important;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15)) !important;
  .customTooltip {
    .customTooltipHeading {
      font-size: 14px;
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }
  .customTooltipList {
    font-size: 13px;
    margin-bottom: 3px;
    display: flex;
    &:last-child {
      margin-bottom: 0px;
    }
    .customTooltipNumber {
      width: 20px;
      min-width: 20px;
      display: inline;
    }
    .customTooltipContent {
      display: inline;
    }
  }
}
.MuiTooltip-arrow {
  &:before {
    background-color: #ffffff !important;
    border: 1px solid #00a751 !important;
  }
}
.selectListStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selectListStyle span {
  padding-top: 4px !important;
  color: #da393c !important;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
}

.logoutHeading {
  padding: 30px 0 20px 0 !important;
  text-align: center;
}

.MuiCircularProgress-root {
  color: #ffffff !important;
}

.type_of_test {
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
  label {
    margin-left: 0px !important;
  }
}

.vesselSelectField {
  .MuiSelect-select {
    min-width: 125px !important;
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a0a8c4 !important;
}

.password_show_hide {
  button {
    margin: 0;
  }
}

// .MuiPaper-root, .MuiBox-root{
//   box-shadow: none !important;
// }

.MuiPopover-paper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.MuiRadio-colorPrimary {
  color: #00a751 !important;
}
.p-relative {
  position: relative !important;
}

.customCheckboxMiddle {
  top: -15px;
}

/***************************************/
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.blinking-text {
  animation: blink 2s linear infinite;
  color: #00a751;
  font-weight: 600;
}
/************************************/

.customHeaderBtn {
  background-color: #00a751;
  font-size: 13px;
  border-radius: 4px;
  color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #009d4c;
  }
}

.customNotificationIcon {
  span {
    top: -10px;
    right: -4px;
    background-color: #f06548;
    font-size: 10px;
  }
}
@media screen and (max-width: $tablet) {
  .div_job_container_info {
    max-height: calc(100vh - 170px);
  }
  .customBodyWithoutBtnContainer {
    max-height: calc(100vh - 140px);
  }
}

.disableViewOilKitsBtn {
  background-color: #ced4da !important;
  color: $backGroundColor_white !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  padding: 6px 18px;
}

.customTableWidth {
  @media screen and (min-width: $tablet) and (max-width: $largeLaptop) {
    width: calc(100% - 30px);
    overflow: hidden;
  }
}

.div_sites_container {
  padding: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 85px);
  @media screen and (max-width: $mobile) {
    padding: 10px 15px;
  }
}
.rightSideContent {
  display: flex;
  justify-content: flex-end;
}

.rightBorder {
  border-right: 1px solid #e9ebec;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.notesData {
  p {
    max-width: 100%;
    margin-bottom: 3px;
    word-break: break-word;
    word-wrap: break-word;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.customJobLocation {
  width: 100%;
  margin: 8px 0px;
}

.bidItemsGroup {
  border-radius: 8px;
  background-color: #f3f3f9;
  border: 1px solid #e7e7e7;
  padding: 20px;
}

.customServiceList {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ffffff;
}

.sidebarSubMenuIcon {
  .ps-menu-icon {
    margin-left: 30px;
    margin-right: 0px;
  }
}

.girlImage {
  width: 100%;
  height: auto;
}

@media screen and (min-width: $tablet) and (max-width: $laptop) {
  .girlImage {
    width: 68%;
  }
  .testMenuContainer.mr-100 {
    margin-right: 60px !important;
  }
}

/******************************/
.mainTest {
  position: relative;
  .Mui-selected {
    background-color: rgba(0, 167, 81, 0.1) !important;
  }
  .MuiTreeItem-content {
    padding: 6px 10px;
    margin-bottom: 3px;
  }
}

.Test {
  top: 0px;
  position: absolute;
  right: 5px;
}

.pointerClass {
  cursor: pointer;
}
/********************************/
.IOSSwitchStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
  margin-top: 25px !important;
}

.IOSSwitchLabel {
  margin-right: 10px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #212529 !important;
}

.dashboardBanner {
  width: 100%;
  img {
    width: 100%;
  }
}

/*********************Dashboard*********************/
.menu1 {
  padding: 7px 0px;
  a {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.dashboardCardContainer {
  border-radius: 6px;
  border: 1px solid #e9ebec;
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 20px 25px;
  gap: 10px;
  .dashboardCardIcon {
    box-shadow: 0px 2px 28px 0px #00000040;
    min-width: 71px;
    max-width: 71px;
    height: 67px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    place-items: center;
    margin-top: -30px;
    transition: all 0.3s linear;
  }
  .purple {
    background-color: #6944ba;
  }
  .orange {
    background-color: #ed8537;
  }
  .green {
    background-color: #67ad5b;
  }
  .blue {
    background-color: #4994ec;
  }
  .dashboardCardContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    place-items: flex-end;
    .dashboardCardText {
      font-size: 16px;
      font-weight: 600;
      text-align: right;
      color: #092245;
      margin-bottom: 20px;
    }
    .dashboardCardValue {
      font-size: 20px;
      font-weight: 600;
      text-align: right;
      color: #092245;
    }
  }
  &:hover .dashboardCardIcon {
    margin-top: -40px;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: $color_white;
  width: 100%;
  text-align: center;
  height: 51px;
  line-height: 51px;
  font-size: 11px;
  font-weight: 600;
  @media screen and (max-width: $mobile) {
    height: auto;
    line-height: normal;
    padding: 10px 0px;
  }
  a {
    color: #00a751;
  }
}
/*********************Dashboard*********************/

.custom-tooltip-red {
  background-color: #00A751;
  color: #ffffff;
}

.popper-arrow {
  position: absolute;
  left: 4px;
  top: 0px;
  transform: translate(0px, 15px);
  margin-left: -12px;
  height: 15px;
  width: 15px;
  &:before {
    content: "";
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-color: currentcolor;
    transform: rotate(45deg);
    background-color: #ffffff;
    border-bottom: 1px solid #00a751;
    border-left: 1px solid #00a751;
  }
}

.customPopper {
  position: absolute;
  padding: 12px 16px;
  border-radius: 4px;
  top: 0px;
  bottom: auto;
  left: 12px;
  right: 0px;
  // transform: translate(4%, -2%);
  z-index: 1;
  font-size: 14px;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.3));
  background-color: #ffffff;
  border: 1px solid #00a751;
  width: 300px;
  max-height: 280px;
}

.customBookmarkPopper {
  position: absolute;
  padding: 12px 16px;
  border-radius: 4px;
  top: 0px;
  bottom: auto;
  left: 12px;
  right: 0px;
  // transform: translate(4%, -2%);
  z-index: 1;
  font-size: 14px;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.3));
  background-color: #ffffff;
  border: 1px solid #00a751;
  width: 300px;
  max-height: 280px;
}

.customPopperLeftAligned {
  left: auto;
  right: 52px;
  top: 0px;
  bottom: auto;
  .popper-arrow {
    right: -8px;
    left: unset;
    &:before {
      border-top: 1px solid #00a751;
      border-right: 1px solid #00a751;
      border-bottom: none;
      border-left: none;
    }
  }
}

.customPopperRightAlignedBottom {
  left: auto;
  right: 52px;
  top: auto;
  bottom: 0px;
  .popper-arrow {
    right: -8px;
    left: unset;
    bottom: 40px;
    top: auto;
    &:before {
      border-top: 1px solid #00a751;
      border-right: 1px solid #00a751;
      border-bottom: none;
      border-left: none;
    }
  }
}

.customPopperLeftAlignedBottom {
  left: 10px;
  right: auto;
  top: auto;
  bottom: 0px;
  .popper-arrow {
    right: unset;
    left: 4px;
    top: unset;
    bottom: 43px;
    &:before {
      border-top: none;
      border-right: none;
      border-bottom: 1px solid #00a751;
      border-left: 1px solid #00a751;
    }
  }
}

.popperCloseButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 20px;
  svg.MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
}

.popperCustomTabBtn {
  button {
    max-height: 37px;
    min-height: 37px;
    padding: 12px;
  }
}

.borderBottom {
  border-bottom: 1px solid #e9ebec;
  padding-bottom: 30px;
  margin-bottom: 14px;
}

.defectContainer {
  display: block;
  padding: 15px 20px;
  color: #212529;
  overflow-y: auto;
  max-height: calc(100vh - 165px);
}

.mainTreeStructure {
  span {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #212529;
  }
  li {
    div.Mui-expanded:first-child {
      &::before {
        content: "";
        width: 1px;
        height: calc(100% - 35px);
        border: 1px dashed #878a99;
        position: absolute;
        top: 16px;
        left: 16px;
      }
    }
    &::after {
      content: "";
      width: 12px;
      height: 1px;
      border: 1px dashed #878a99;
      position: absolute;
      top: 15px;
      right: 98%;
    }
  }
}

.mainTreeStructure > .mainTest > li {
  &::after {
    border: none !important;
  }
}

.editDefectNode {
  position: absolute;
  top: 4px;
  left: 61px;
  backdrop-filter: blur(50px) !important;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  input {
    background-color: #ffffff;
    border: none;
    outline: none;
  }
}

.smallDefectBtn {
  display: flex;
  button {
    font-size: 13px;
    margin-right: 5px;
    border-radius: 4px;
  }
  .themeBtnGreen {
    background-color: #009d4c;
    color: #ffffff;
  }
  .themeBtnGray {
    background-color: #ced4da;
  }
}

.addDefectNode {
  display: flex;
  justify-content: space-between;
  place-items: baseline;
  margin-left: 18px;
  .addDefectInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    input{
      padding: 0px 4px;
    }
    &::after {
      content: "";
      width: 17px;
      height: 1px;
      border: 1px dashed #878a99;
      position: absolute;
      top: 47px;
      right: 95%;
    }
  }
}

.defectNodeBtns {
  position: absolute;
  right: 10px;
  top: 0px;
}

.defect_action_checkbox {
  display: flex;
  justify-content: flex-end;
  .container_checkmark_div {
    margin-right: 0px;
  }
}

.Save_next_defect_code {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
/****************For submenu active***********/
.SubmenuStyle {
  background: rgba(0, 167, 81, 0.1);
}

.SubmenuStyle .margin_3 {
  color: #00a751 !important;
}

.MuiError {
  fieldset {
    border-color: #dc2800 !important;
  }
}

.cityCircularLoading {
  height: 20px;
  span {
    svg {
      color: green;
    }
  }
}

.colorPickerStyle {
  padding: 0px;
  border: 0px;
  background-color: #f3f3f9;
  width: 100%;
  height: 40px !important;
  border-radius: 10px !important;
}
.div_job_new {
  padding: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 295px);
}

//New CSS added by Manish Rawat
.edit_vessel_tab_sec {
  width: 100%;
  .edit_vessel_tab_row {
    position: relative;
    div {
      position: relative;
      @media screen and (max-width: $mobile) {
        overflow-y: scroll !important;
        margin-bottom: 0px !important;
        padding-bottom: 10px !important;
      }
    }
  }
}

.job_order_right_sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  width: 100%;
  .job_order_id_txt {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    min-width: 100px;
  }
}

#edit_vessel_defect_search_row {
  background: transparent;
  @media screen and (max-width: $mobile) {
    background: #fff;
    .save_and_next_div {
      margin-right: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.JobOrderLink {
  text-decoration: none;
  color: #00a751;
  &:hover {
    color: #00a751 !important;
  }
  background: rgba(0, 167, 81, 0.1);
  border-radius: 4px;
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 400;
}

.ps-sidebar-root {
  max-width: 250px !important;
}

.MuiClockPointer-thumb {
  border: 16px solid #00a751 !important;
}

.MuiClockPointer-root {
  background-color: #00a751 !important;
}

.MuiClock-pin {
  background-color: red !important;
}

.MuiClock-amButton {
  background-color: #00a751 !important;
}

.MuiClock-pmButton {
  background-color: #00a751 !important;
}

.MuiPickersLayout-actionBar {
  button {
    background-color: #00a751 !important;
    color: white !important;
    height: 30px !important;
  }
}

.MuiClock-clock {
  background-color: #e5f6ed !important;
}
.alignArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.customerSeeMore {
  cursor: pointer;
  padding: 30px;
}

.disableSelect{
  div{
    cursor: not-allowed !important;
  }
}

.react-transform-wrapper{
  width: 98.5% !important;
  height: 48vh !important;
  background-color: #e5f6ee;
  border: 1px solid #00A751;
  border-radius: 6px;
  margin: 10px;
  box-sizing: border-box;
}
.ReportTableListStyle {
  overflow-y: auto;
  max-height: calc(73vh - 345px) !important;
  @media screen and (max-width: $tablet) {
    max-height: calc(100vh - 430px) !important;
  }
  @media screen and (max-width: "422px") {
    max-height: calc(100vh - 450px) !important;
  }
}

.MuiMenu-list{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.reportWorkspaceTableStyle {
  overflow-y: auto;
  max-height: calc(94vh - 345px) !important;
  @media screen and (max-width: $tablet) {
    max-height: calc(98vh - 430px) !important;
  }
  @media screen and (max-width: "422px") {
    max-height: calc(92vh - 450px) !important;
  }
}

.custom-toast {
  background-color: #e5f6ed !important;
  color: #00A751 !important;
}

.mt20{
  margin-top: 20px;
}
.otherCommentInputStyle{
  display: flex;
  flex-direction: row;
  place-items: flex-start;
  label{
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0px;
    display: flex;
  }
  input{
    padding: 3px 8px;
  }
}

.rs-calendar-table-cell-in-range div {
  background: #e5f6ee;
}

.rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
  background-color: #009b4b !important;
  color: #fff !important;
}

.customAttachmentStyle{
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #F06548;
}
.p16{
  padding: 16px;
}
.globalTabScroll{
  .MuiTabs-flexContainer{
  overflow-x: auto;
  scrollbar-width: none;
  }
}
.fullWidthBox{
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .customRecommendationScroll{
    max-height: calc(100vh - 215px);
  }
}

.ReInspectionLetterTableListStyle {
  overflow-y: auto;
  max-height: calc(97vh - 345px) !important;
  @media screen and (max-width: $tablet) {
    max-height: calc(100vh - 430px) !important;
  }
  @media screen and (max-width: "422px") {
    max-height: calc(100vh - 450px) !important;
  }
}

.sideBarMenuClass > ul > li:first-child {
  position: sticky;
  top: 0;
  z-index: 2;
  margin: 0px;
  background: rgb(249, 249, 249);
}

/*********************************************************/

.customBookmarkPopperLeftAligned {
  left: auto;
  right: 52px;
  top: 0px;
  bottom: auto;
  .popper-arrow {
    right: -8px;
    left: unset;
    &:before {
      border-top: 1px solid #00a751;
      border-right: 1px solid #00a751;
      border-bottom: none;
      border-left: none;
    }
  }
}

.customBookmarkPopperRightAlignedBottom {
  left: auto;
    right: 45px;
    top: 0px;
    bottom: auto;
  .popper-arrow {
    right: -8px;
    left: unset;
    bottom: 46px;
    top: -4px;
    &:before {
      border-top: 1px solid #00a751;
      border-right: 1px solid #00a751;
      border-bottom: none;
      border-left: none;
    }
  }
}

.customBookmarkPopperLeftAlignedBottom {
  left: 18px;
  right: auto;
  top: 0px;
  bottom: auto;
  .popper-arrow {
    right: unset;
    left: 4px;
    top: 0px;
    bottom: 43px;
    &:before {
      border-top: none;
      border-right: none;
      border-bottom: 1px solid #00a751;
      border-left: 1px solid #00a751;
    }
  }
}

.noBreakWord{
  white-space: nowrap;
}

.container_label_draggable {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #212529;
}