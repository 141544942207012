
@import "../../../themes/colors.scss";
@import "../../../styles/globalmixin.scss";
@import "../../../styles/breakpoint";


.customerInfo_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9ebec;
    padding: 15px 20px;
    .back_to_profile_link {
      a {
        font-size: 14px;
        text-decoration: none;
        color: $primaryColor;
        font-weight: 500;
      }
    }
  }
  .customerInfo {
    font-weight: 600;
    font-size: 15px;
    color: $cyanBlueColor;
  }
  .customerInfo_main_container {
    padding: 16px 20px;
  }
  .no_label_Style {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 30px;
  }
  .label_Style_Customer {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 9px;
  }
  .save_and_next_div {
    @include display_items(flex, flex-end, "");
  }
  .button_margin_left {
    margin-left: 16px;
  }

  .edit_card{
    margin: 20px 24px 20px 10px;
    min-height: 382px;
    border-radius: 7px !important;
    @media screen and (max-width: 1200px) {
      margin: 0 20px 20px 20px;
    }
  }

// for image section
.profile_scroll {
  overflow-y: scroll;
  max-height: 90vh;
}

.main_parent{
  position: relative;
  width: 100%;
  padding: 20px 0px 20px 24px;
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }
}

.top_green {
  height: 170px;
  background-color: $primaryColor;
  border-radius: 7px 7px 0 0;
}
.top_green.edit_profile {
  height: 182px;
  background-color: $primaryColor;
  border-radius: 7px 7px 0 0;
}

.bottom-white{
  background-color: $backGroundColor_white;
  height: 215px;
  text-align: center;
  padding-top: 90px;
  position: relative;
  border-radius: 0 0 7px 7px;
}
.bottom_white_edit_profile{
  background-color: $backGroundColor_white;
  height: 200px;
  text-align: center;
  padding-top: 110px;
  position: relative;
  border-radius: 0 0 7px 7px;
}

.profile_image {
  height: 160px;
  width: 160px;
  position: absolute;
  background-color: $backGroundColor_white;;
  border-radius: 50%;
  top: -85px;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
}

.profile_name_sec {
  padding-bottom: 25px;
  .user_name {
    font-size: 17px;
    font-weight: 600;
    color: #000;
  }
  .user_email {
    margin-top: 2px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: $primaryColor;
  }
  .user_contact_no {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }
}

//Additional one
  .dropdown_container {
  display: flex;
  align-items: center;
}
.billing-checkbox{
  display: flex;
  justify-content: flex-end;
}
.div_container_CustomerInfo {
  // margin: 20px 0px;
  background: #f3f3f9;
}

.edit_btn_sec {
  button {
    padding: 0;
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 600;
    color: $primaryColor;
    height: 21px;
  }
}

.ProfileImageDialogButton{
  h2{
    padding: 0px !important;
    button{
      background-color: white !important;
      padding: 2px !important;
    }
  }
}