@import "../../../styles/breakpoint";
@import "../../../styles/variable";

.activityHistoryMainContainer {
  width: 100%;
  border-radius: 4px;
  background-color: rgba(41, 156, 219, 0.05);
  display: flex;
  padding: 20px 35px 20px 20px;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: $tablet) {
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
  }
  .activityHistoryLeftSide {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  .activityHistoryDetails {
    margin-left: 20px;
    .activityHistoryName {
      font-size: 13px;
      font-weight: 500;
      span {
        font-weight: 700;
      }
    }
  }
}

.activityHistoryRightSide {
  font-size: 13px;
  font-weight: 500;
  color: #495057;
  display: flex;
  justify-content: flex-end;
  place-items: center;
  @media screen and (max-width: $tablet) {
    margin-left: 55px;
    margin-top: 15px;
    justify-content: flex-start;
  }
}

.activityHistoryLetter {
  height: 36px;
  width: 36px;
  font-size: 13px;
  color: #00a751;
  font-weight: 500;
  border-radius: 100%;
  background-color: #c3e9db;
  display: flex;
  justify-content: center;
  place-items: center;
}

.activityHistoryCreate {
  padding: 3px 10px;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  background-color: #dde4eb;
}

.activityHistoryDone {
  padding: 3px 10px;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  color: #00a751;
  background-color: rgba(0, 167, 81, 0.1);
}

.activityHistoryNotDone {
  padding: 3px 10px;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  background-color: #F065481A;
  color: #F06548;
}
.activityIcon {
  padding: 0px 10px;
}

.activitycontainer {
  padding-top: 8px;
}
