@import '../../themes/colors.scss';
@import '../../styles/breakpoint';

.customPaper {
    border-radius: 0px !important;
    box-shadow: none !important;
    height: 70%;
}

.tableStyle thead {
    text-transform: uppercase;
    white-space: nowrap;
    height: 48px;
    width: 100%;
    position: relative;
    z-index: 0 !important;
}

.tableStyle thead tr th {
    background-color:$backGroundColor_cyanBlue;
    color: $color_tealishBlue;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-transform:capitalize;
    border: none;
}

.tableStyle tr td {
    line-height: unset;
    padding: 6px 16px;
    text-align: left;
    font-size: 13px;
    color: $color_tealishBlue;
    word-break: break-all;
}

.wdth-25 {
    width: 25%;
}
.wdth-3 {
    width: 3%;
}
.wdth-5 {
    width: 5%;
}
.wdth-6 {
    width: 6%;
}

.wdth-20 {
    width: 20%;
}

.wdth-10 {
    width: 10%;
}

.wdth-35 {
    width: 35%;
}

.subHeaderTextfield {
    width: '260px';
    height: '37px';
}

.mt-16 {
    margin-top: 16px !important;
}

.customPaper::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

.customPaper::-webkit-scrollbar-track {
    border-radius: 10px;
}

.customPaper::-webkit-scrollbar-thumb {
    background: $backGroundColor_HARBOUR_FOG;
    border-radius: 10px;
}

.customPaper::-webkit-scrollbar-thumb:hover {
    background: $backGroundColor_ROYCROFT_PEWTER;
}
  
.tableCell-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        padding-right: 5px;
    }
} 

.table-Card-style{
    margin: 20px 25px;
}

.table_Card_style_dialog{
    padding: 0px;
}

.tableCell-edit{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.table-pagination-style {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background-color: #ffffff;
    @media screen and (max-width: $mobile) {
        padding: 15px 0px;
    }
}

.font-size{
    font-size: 14px;
}
.table-select-style{
    height: 34px;
    width: 82px; 
}

.table-row-height{
    height: 52px;
}
.select-style{
    padding: 0px 8px; 
}

.main-div{
    padding: 16px 22px;
}
.div-style-button{
    display: flex;
    justify-content: flex-end;
}
.add-btn{
    background-color: $primaryColor !important;
    color:$backGroundColor_white !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
}