@import "../../themes/colors.scss";
@import "./mixin";
@import "../../styles/variable";
@import "../../styles/breakpoint";

.div-password{
    display: flex;
    @media screen and (max-width: $mobile) {
      background: $white;
      padding: 0 5px;
    }
}
.div_enter_password{
    display: flex; 
    justify-content: flex-start;
    margin-top: 17px;
}

.style_forgot_password{
    display: flex;
    justify-content: center;
    margin-top: 0;
    .forgotLabelStyle {
      color: #00A751;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      background: transparent;
    }
}

.button-color{
    background-color: $primaryColor !important;
    box-shadow: none;
}

.button_back_login{
    display: flex;
    justify-content: center;
    .backToLogIn {
      color: #121214;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      background: transparent;
    }
}
.div_label_Email{
    text-align: left;
}

.text_field_margin{
  margin-top: 6px !important;
  background: #fff;
  padding-right: 0px !important;
  input {
    font-size: 13px !important;
    height: 21px !important;
    color: $labelColor !important;
    font-family: $primaryFont;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    background: #FFF;
    opacity: 1;
    font-weight: 500;
    padding-right: 0px !important;
  }
  ::placeholder{
    color: #878A99 !important;
    opacity: 1 !important;
  }
  &.password_{
    input {
      border: none !important;
    }
  }
}
.padding_0{
  div{
    padding-right: 0px !important;
  }
}

.login_submit_btn {
  display: flex;
  width: 100%;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #00A751;
  border: none;
  color: #fff;
  margin-top: 20px !important;
}

.sub_div{
    width: 100%;
    margin-top: 21px; 
}
.div_button_change{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    p{
      font-size: 14px !important;
      color: #212529 !important;
    }
    button{
      font-size: 14px !important;
      padding: 0 !important;
      height: auto !important;
    }
}
#password {
  border-right: none;
}
.password_show_hide {
  position: absolute;
  right: 0;
  width: 45px;
  button{
    margin: 0;
    background: transparent !important;
    img{
      width: 20px;
    }
  }
}
.div_button_resend{
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  button{
    padding: 0 !important;
    height: auto !important;
    color: #212121 !important;
    cursor: pointer !important;
    background: transparent !important;
  }
  button.Mui-disabled{
    color: #878A99 !important;
  }
  p{
    font-family: $primaryFont;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.login_email_field{
  p{
    margin: 0;
    background: transparent;
    position: absolute;
    bottom: -23px;
  }
}
.login_password_field{
  p{
    margin: 0;
    background: transparent;
    position: absolute;
    bottom: -23px;
  }
}
.reset_button-style{
    margin: 0px !important;
    padding: 0px !important;
}

.mt-26{
    margin-top: 26px;
}

.div_Create_Password{
    width: 100%;
    margin-top: 21px;
}

.div_pass_typo{
    @include style_typo(20px);
    margin-top: 0;
    p{
      font-family: $primaryFont;
      font-size: 13px;
      color: #878A99;
    }
}

.div_require_typo{
    margin-top: 10px;
    margin-bottom: 4px;
    p{
      font-family: $primaryFont;
      font-size: 13px;
      color: #DC2800;
      font-weight: 500;
      text-align: left;
      line-height: normal;
    }
}

.container_div{
    display: flex;
    height: 100vh; 
    overflow: hidden;
    @media screen and (max-width: 568px) {
      overflow: initial;
    }
}

#sideBarMenuShow {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 55px;
  z-index: 99;
}
#sideBarMenuHide {
  display: initial;
  @media screen and (max-width: 568px) {
    display: none;
  }
}
.forDeesktopView {
  display: block;
  @media screen and (max-width: 568px) {
    display: none;
  }
}
.forMobileView {
  display: none;
  @media screen and (max-width: 568px) {
    display: block;
  }
}

.sub_div_navbar{
    display: flex;
    flex-direction: column;
    width: 96%;
    background-color: #f3f3f9;
    overflow: hidden;
    @media screen and (max-width: $desktop) {
      width: calc(100% - 80px);
    }
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
}

.inner_div_navBar{
    display: flex;
    align-items: center;
    padding: 15px 27px;
    justify-content: space-between;
    flex-direction: row;
    background-color: #ffff;
    border-bottom: 1px solid #E9EBEC;
    @media screen and (max-width: 568px) {
      padding: 10px 25px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
      z-index: 9;
    }
}

.link_style{
    margin: 0px 8px;
    font-size: 14px;
    text-decoration: none;
    color: $cyanBlueColor;
}
.relative_div{
    position: relative;
}

.div_inner_header{
    display: flex; 
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #ffffff;
    border-radius: 4px;
    p {
      margin: 0px 4px;
    }
    .header_profile_pic {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e1e1e1;
      border-radius: 20px;
      border: 3px solid #fff;
      margin-right: 5px;
      margin-left: 5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      color: $primaryColor;
      font-weight: bold;
      span {
        font-size: 12px;
        margin-top: 2px;
      }
  }
}

.dropdown{
    margin: 0px 12px;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.margin8 {
    margin: 0px 8px;
}

.fontSize{
    font-size: 13px;
}

.otp_error{
    border: 1px solid $redColor !important;
}
.leftContainer {
    background-color: $primaryColor !important;
    border-radius: 0px  !important;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    place-items: center;
    flex-direction: column;
}
.rightContainer {
    width: 90%;
    max-width: 450px;
    border: 3px solid #00A751;
    border-style: double;
    padding: 40px;
    box-shadow: 0px 2px 25px 0px #00000040;
    @media(max-width:900px){
        width: 100%;
    }
    @media(max-width:450px){
    padding: 40px 16px;
    }
  }
  .taiLogo {
    width: 250px;
    height: 84px;
    @media(max-width:450px){
      width: 180px;
    }
  }

  .mobStyle {
    display: flex !important;
    height: 100vh;
    text-align: center;
    place-items: center !important;
    justify-content: center !important;
    background: #FBFBFB;
    @media(max-width:900px){
      background-position-y: -10%;
      padding: 0px 12px !important;
    }
  }
  .loginContainer {
    // width:'40%',
    h2{
      color: #092245;
      font-size: 25px;
      font-weight: 600;
      padding: 30px 0px;
      font-family: $primaryFont;
      margin-bottom: 0px;
      line-height: 40px;
    }
    button{
      text-transform: none;
      margin: 20px 0px 14px 0px;
      font-weight: 500;
      box-shadow: none;
      font-size: 13px;
      font-family: $primaryFont;
      height: 36px;
    }
  }

  .labelStyle{
    font-weight: 500;
    font-size: 13px;
    color: $labelColor;
    font-family: $primaryFont;
  }

  .forgotLabelStyle{
    font-weight: 400;
    font-size: 16px;
    color: $primaryColor !important;
    margin: 0px !important;
  }

  .backToLogIn{
    margin: 0px !important;
    font-weight: 500;
    font-size: 16px;
    color: #121214 !important;
  }

  .verifyCodeLabel{
    font-weight: 500;
    font-size: 12px;
    color: #878A99;
    width: 100%;
    margin-bottom: 0;
  }

  .changeButton{
    font-weight: 500;
    padding: 0px !important;
    font-size: 14px;
    color: $primaryColor !important;
    margin: 0px !important;
  }

  .divContainer{
    padding: 10px 10px; 
    display: flex; 
    flex-direction: column;
    border-radius: 6px;
    background: #EFF2F7;
    p {
      font-family: $primaryFont;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #878A99;
    }
  }

  .otpSection {
    display: flex;
    justify-content: center; 
    padding: 15px 0px;
    input{
      width: 40px !important;
      height:38px !important;
      border-radius: 6px;
      border: 1px solid #CED4DA;
      outline-width: 0px !important;
      @media screen and (max-width: '450px') {
        margin-right: 5px !important;
      }
    }
    input:focus-visible {
      width: 40px !important;
      height: 38px !important;
      border-radius: 6px;
      border: 1px solid #bec6cf !important;
    }
    input:last-child{
      margin-right: 0px !important;
    }
  }

  .percentage_div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }

  .percent{
    margin-left: 8px;
  }

  .Mui-disabled{
    opacity: 1 !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.65) !important;
  }