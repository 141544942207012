@import '../../../styles/breakpoint';
.type_of_test {
    label {
        @media screen and (max-width: $mobile) {
            margin: 0;
        }
    }
    @media screen and (max-width: $mobile) {
        display: block;
        width: 100%;
    }
}
.checkbox_in_row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .checkbox_with_label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        position: relative;
        margin-bottom: 25px;
        .container_checkmark_div {
            margin-left: 0;
            display: block;
            position: relative;
            label {
                padding: 0;
                padding-right: 10px;
                color: #333;
                position: initial;
            }
            .checkmark {
                right: -25px;
                left: initial;
            }
        }
        label {
            font-size: 14px;
            color: #333;
        }
        @media screen and (max-width: $mobile) {
            width: 100%;
        }
    }
}
.inline_checkbox {
    display: flex;
    .container_checkmark_div {
        margin: 0;
        .container_label{
            @media screen and (max-width: $mobile) {
                padding-left: 0;
            }
        }
    }
    label {
        font-size: 14px;
        color: #333;
    }
    @media screen and (max-width: $mobile) {
        margin-bottom: 20px;
    }
}
.inline_checkbox_in_a_row {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    background: #fbfbfb;
    padding: 7px 15px 15px 18px;
    border-radius: 5px;
    // border: 1px solid #cdcdcd;
    .container_checkmark_div {
        padding-left: 15px;
        position: relative;
        padding-right: 15px;
        margin-right: 0;
        @media screen and (max-width: $mobile) {
            margin-left: 0;
            width: auto;
            border: 1px dotted #ccc;
            padding-bottom: 15px;
            margin-bottom: 10px;
            background: #f9f9f9;
            padding-top: 6px;
            border-radius: 5px;
            margin-right: 10px;
            padding-left: 6px;
            padding-right: 0;
        }
        &::after {
            content: '';
            width: 1px;
            height: 41px;
            position: absolute;
            top: -6px;
            right: 0;
            background: #cdcdcd;
        }
        @media screen and (max-width: $mobile) {
            &::after {
                display: none;
            }
        }
    }
    @media screen and (max-width: $mobile) {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 0;
        background: none;
        border: none;
    }
}
.manager_textField{
    margin-top: -35px;
    margin-left: 25px;
}

.Copy_button {
    display: block;
    text-align: end;
    /* margin: 5px; */
    padding: 20px;
}