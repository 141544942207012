@import '../../../themes/colors.scss';
@import "../../../styles/globalmixin.scss";


.div_container_CustomerInfo {
    // margin: 20px 0px;
    background: #f3f3f9;
}


.save_and_next_div {
    @include display_items(flex, flex-end, "");
}


.container_job_search {
    @include display_items(flex, "", center);
}

.button_margin_left {
    margin-left: 16px;
}
