.input_file_upload {
    display: none;
  }

.label_file_upload {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 4px;
  border: 1px dashed #ced4da;
  background: rgba(41, 156, 219, 0.05);
}

.drag_drop_text {
  font-size: 15px;
  max-width: 200px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  margin: 10px 0px !important;
  line-height: 21px;
  color: rgba(135, 138, 153, 1);
}

.upload_button {
  cursor: pointer;
  padding: 10px 18px;
  border-radius: 4px;
  background: #00a751;
  border: none;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
}

.drag_file_element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.save_cancel{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 0px 0px;
}

.file_name{
  font-size: 13px;
  font-weight: 500;
  padding: 5px 0px;
  margin: 0px;
  color: #00a751;
  }