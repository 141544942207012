@import "../../../../../../../themes/colors.scss";
@import "../../../../../../../styles/globalmixin.scss";
@import "../../../../../../../styles/breakpoint";

.customerInfo {
  font-weight: 600;
  font-size: 15px;
  color: $cyanBlueColor;
}

.cancel-icon {
  text-align: end;
}
.save_and_next_div {
  @include display_items(flex, flex-end, "");
}
.button_cancel {
  background-color: $background-color-cyan-blue !important;
  color: $cyanBlueColor !important;
  font-size: 13px !important;
  font-weight: 500;
  height: 40px;
  text-transform: capitalize !important;
}
.button_margin_left {
  margin-left: 16px;
}

.bidsSiteDiv {
  padding: 15px 20px;
  background-color: white;
  font-size: 15px;
  margin-top: 20px;
  font-weight: 600 !important;
  border-top-left-radius: 6px 6px;
  border-top-right-radius: 6px 6px;
}
