@import "../../themes/colors.scss";


// for checkbox

.form-check {
    display: flex;

    .form-check-label {
        margin-right: 10px;
    }

    .input_checkbox_style {
        width: 28px;
        height: 28px;
        border: 1px solid #CED4DA;
        border-radius: 4px;

    }

}

.container_checkmark_div {
    display: flex;
    margin-right: 10px;
    height: 29px;
}

/* The container */
.container_checkmark {
    display: block;
    position: relative;
    padding-left: 35px;
    // margin-top:10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container_checkmark input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #CED4DA;
    border-radius: 4px;
}

.checkmark-without-label {
    position: absolute;
    top: -12px !important;
    left: -14px;
    height: 28px;
    width: 28px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #CED4DA;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container_checkmark:hover input~.checkmark {
    background-color: #ccc;
}

.container_checkmark:hover input~.checkmark-without-label {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container_checkmark input:checked~.checkmark {
    background-color: #fff;
    border: solid 1px green;
}

.container_checkmark input:checked~.checkmark-without-label {
    background-color: #fff;
    border: solid 1px green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    background-color: #FFFFFF;
}

.checkmark-without-label:after {
    content: "";
    position: absolute;
    display: none;
    background-color: #FFFFFF;
}

/* Show the checkmark when checked */
.container_checkmark input:checked~.checkmark:after {
    display: block;
}

.container_checkmark input:checked~.checkmark-without-label:after {
    display: block;
}

/* Style the checkmark/indicator */
.container_checkmark .checkmark:after {
    left: 10px;
    top: 3px;
    width: 7px;
    height: 16px;
    border: solid #059862;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #fff;
}

.container_checkmark .checkmark-without-label:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid #059862;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #fff;
}

.container_label {
    margin-top: 6px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    padding-right: 8px;
    color: #212529;
}

.container_label_14px {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding: 0px 8px;
    color: $color_DUSTY_CHIMNEY;
}