@import "../../../../../themes/colors.scss";

// @mixin tubeChartDotButton($width, $height, $colorCode, $bgColor) {
//   width: $width;
//   height: $height;
//   margin: 0;
//   border: none;
//   outline: none;
//   -webkit-box-shadow: inset 0px 0px 0px 0.5px $colorCode;
//   -moz-box-shadow: inset 0px 0px 0px 0.5px $colorCode;
//   box-shadow: inset 0px 0px 0px 0.5px $colorCode;
//   background-color: $bgColor;
//   border-radius: 50%;
//   text-align: center;
//   font-weight: 400;
// }

.displayRow {
  display: flex;
  flex-direction: row;
}

.tubeChartBox{
  width: 33%;
  // max-height: 400px;
  max-height: calc(100vh - 310px);
  min-height: 300px;
  overflow: auto;
  display: block;
  margin-bottom: 20px;
  @media screen and (max-width: "600px") {
    min-width: 90%;
  }
}
.singleTubeChartBox{
  // width: 90%;
  width: -webkit-fill-available;
  // max-height: -webkit-fill-available;
  max-height: calc(100vh - 310px);
  min-height: 300px;
  overflow: auto;
  display: block;
  margin-bottom: 20px;
}
.doubleTubeChartBox{
  width: 50%;
  max-height: -webkit-fill-available;
  min-height: 300px;
  overflow: auto;
  display: block;
  margin-bottom: 20px;
}
// --- Classes for screenshots ---------->>>>>>>>>>>>>>>>

.tubeChartScreenShotBox{
  // width: 33%;
  // max-height: 400px;
  // min-height: 300px;
  // min-height: 100vh !important;
  overflow: unset;
  display: block;
  margin-bottom: 20px;
}

.singleTubeChartScreenShotBox{
  // width: 80%;
  // max-height: -webkit-fill-available;
  // min-height: 300px;
  // min-height: 100vh !important;
  overflow: unset;
  display: block;
  margin-bottom: 20px;
}
.doubleTubeChartScreenShotBox{
  // width: 50%;
  // max-height: -webkit-fill-available;
  // min-height: 300px;
  // min-height: 100vh !important;
  overflow: unset;
  display: block;
  margin-bottom: 20px;
}

// ------------->>>>>>>>>>>>>>>>>>>>

.displayTubeRow {
  display: flex;
  flex-direction: row;
  // height: calc(100vh - 556px);
  // height: calc(100vh - 385px);
  height: calc(100vh - 310px);
  // overflow: hidden;
  gap: 24px;
  justify-content: space-around;
  padding: 0px 16px;
  // padding-bottom: 30px;
  @media screen and (max-width: "600px") {
    overflow-x: auto;
  }
}

.tubeChartBoxPdf{
  width: 100%;
  min-height: 290px;
  display: flex;
  justify-content: center;
  place-items: center;
  padding-top: 20px;
}

.displayBlock {
  // display: block;
  // height: calc(100vh - 400px);
  height: -webkit-fill-available;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  place-items: center;
  flex-direction: column;
  padding-bottom: 50px;
}

// ---------- ScreenShot Main Div Class ----------------

// .displayTubeScreenShotRow {
//   display: flex;
//   flex-direction: row;
//   height: auto !important;
//   gap: 24px;
//   justify-content: space-around;
//   padding: 0px 16px;
// }

// .displayScreenShotBlock {
//   height: auto !important;
//   display: flex;
//   justify-content: flex-start;
//   place-items: center;
//   flex-direction: column;
//   padding-bottom: 50px;
// }

.displayScreenShotBlock {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 400px);
  gap: 24px;
  justify-content: space-around;
  overflow: scroll;
  padding-bottom: 50px;
}

// ------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>

.mainPolygonClass {
  padding: 10px 20px;
  transition: transform 0.3s ease;
  // transform-origin: center top;
  transform-origin: left top;
}

.gridInvertedRow {
  display: flex;
  // gap: 2px;
  margin-top: 14px;
  // justify-content: center;
  width: 100%;

  justify-content: center;
  place-items: flex-start;
  padding: 0px 20px;
  min-width: 800px;

  .rowItemInverted {
    position: relative;
    margin-left: -1px;
    &:nth-child(2n + 0) {
      margin-top: 10px;
    }
  }
  .rowItemInverted90 {
    position: relative;
    margin-left: -1px;
    &:nth-child(2n + 0) {
      margin-top: 2px;
    }
  }
  .rowItemInverted50to90 {
    position: relative;
    margin-left: -1px;
    &:nth-child(2n + 0) {
      margin-top: 5px;
    }
  }
  .rowItemInverted50to80 {
    position: relative;
    margin-left: -1px;
    &:nth-child(2n + 0) {
      margin-top: 14px;
    }
  }
  .rowItemInverted30to50 {
    position: relative;
    margin-left: -1px;
    &:nth-child(2n + 0) {
      margin-top: 8px;
    }
  }
  .rowItemInverted15to30 {
    position: relative;
    margin-left: -1px;
    &:nth-child(2n + 0) {
      margin-top: 15px;
    }
  }
  .rowItemInverted15 {
    position: relative;
    margin-left: -1px;
    &:nth-child(2n + 0) {
      margin-top: 25px;
    }
  }
  @media screen and (max-width: "600px") {
    overflow: auto;
    justify-content: flex-start;
  }
}

.pdfTubeChartCenter{
  justify-content: center;
}

.polygrame {
  text-align: center;
  // min-width: 800px;
  min-width: 1100px;
  // min-width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  .eccentricBigMargin {
    margin-left: 30px;
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .eccentricMargin {
    margin-left: 20px;
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .eccentricMargin12{
    margin-left: 16px;
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .eccentricMargin8{
    margin-left: 14px;
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .eccentricMargin7{
    margin-left: 10px;
    margin-bottom: -1px;
    margin-top: -1px;
  }
  .eccentricMargin5{
    margin-left: 8px;
    margin-bottom: -1px;
    margin-top: -1px;
  }

  .invertedRowMargin {
    margin-bottom: -10px;
  }
  .invertedmargin {
    margin-top: 10px !important;
  }

  .gridRow {
    display: flex;
    justify-content: center;
    // margin-bottom: -2px;
  }
}

@media screen and (max-width: "768px") {
  .polygrame {
    min-width: max-content;
  }
}

.dotButton {
  width: 30px;
  height: 30px;
  margin: 0;
  // border: 1px solid #262626;
  border: none;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  background-color: transparent;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}

.dotButtonInactive {
  width: 30px;
  height: 30px;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}

.bigBotButton {
  width: 50px;
  height: 50px;
  margin: 0;
  // border: 1px solid #262626;
  border: none;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 0px 0.8px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 0px 0.8px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 0.8px rgba(0, 0, 0, 1);
  background-color: transparent;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}

.bigDotButtonInactive {
  width: 50px;
  height: 50px;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}

.MediumDotButton {
  width: 16px;
  height: 16px;
  margin: 0;
  // border: 1px solid #262626;
  border: none;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  background-color: transparent;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  // @include tubeChartDotButton('12px', '12px', 'rgba(0,0,0,1)', '#FFFFFF');
}

.MediumDotButtonInactive {
  width: 16px;
  height: 16px;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  // border: 1px solid #111;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  // @include tubeChartDotButton('12px', '12px', 'rgba(0,0,0,0)', 'transparent');
}

.smallDotButton {
  width: 14px;
  height: 14px;
  margin: 0;
  // border: 1px solid #262626;
  border: none;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  background-color: transparent;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  // @include tubeChartDotButton('8px', '8px', 'rgba(0,0,0,1)', '#FFFFFF');
}

.smallDotButtonInactive {
  width: 14px;
  height: 14px;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  // @include tubeChartDotButton('8px', '8px', 'rgba(0,0,0,0)', 'transparent');
}

.mediumSmallDotButton {
  width: 10px;
  height: 10px;
  margin: 0;
  border: none;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  background-color: transparent;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  font-weight: 400;
  // @include tubeChartDotButton('7px', '7px', 'rgba(0,0,0,1)', '#FFFFFF');
}

.mediumSmallDotButtonInactive {
  width: 10px;
  height: 10px;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  // -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0);
  // -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0);
  // box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0);
  box-shadow: none;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  font-weight: 400;
  // @include tubeChartDotButton('7px', '7px', 'rgba(0,0,0,0)', 'transparent');
}

.extraSmallDotButton {
  width: 8px;
  height: 8px;
  margin: 0;
  // border: 1px solid #262626;
  border: none;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 1);
  background-color: transparent;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  font-weight: 400;
}

.extraSmallDotButtonInactive {
  width: 8px;
  height: 8px;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  font-weight: 400;
}

// .fs5{
//   font-size: 5px !important;
//   font-weight: 300 !important;
//   color: #262626;
// }
// .fs4{
//   font-size: 4px !important;
//   font-weight: 300 !important;
//   color: #262626;
// }
// .fs3{
//   font-size: 2px !important;
//   font-weight: 300 !important;
//   color: #262626;
// }

.tubeChartPanel {
  border: 1px solid green;
  width: 100%;
  // align-items: center;
  padding: 10px;

  .iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;
    .panelIcon {
      border: 1px solid black;
      width: 34.5px;
      height: 34.5px;
    }
  }
}

.whiteBackground {
  background-color: white !important;
}

.textFieldSize {
  max-width: 200px !important;
}

.textStyle {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  margin-top: 10px;
  line-height: 15.73px;
  text-align: left;
}

.button_cancel_vessel {
  background-color: #f06548 !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: 500;
  padding: 9px 18px !important;
  text-transform: capitalize !important;
}

.vesselDiv {
  padding: 10px;
}

.arrowPointer {
  cursor: pointer;
}

.arrowDisable {
  cursor: not-allowed;
}


.printDisplayTubeRow {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 400px);
  gap: 24px;
  justify-content: space-around;
  padding: 0px 16px 60px;
  margin: 0px 16px 60px;
  overflow: scroll;
}

.printTubeChartBox{
  width: 100%;
  max-height: unset;
  min-height: unset;
  overflow: unset;
  display: block;
  margin-bottom: 20px;
}

.printPolygrame{
  min-width: unset;
}

.vesselTubeChartContainer{
  height: 100vh;
  @media screen and (max-width: "600px") {
    height: 88vh;
    overflow-y: auto;
  }
}