.mainWrapper{
    border-radius: 4px;
    background: #FFFFFF;
    padding: 20px 20px;
    flex-shrink: 0;
    width: 100%;
}
.mainTab{
    border-radius: 4px;
    cursor: pointer;
    background: rgba(0, 167, 81, 0.10);
    width: 42px;
    height: 35px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00A751;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.selectedMainTab{
    border-radius: 4px;
    background: #00A751;
    width: 42px;
    height: 35px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.mainTabWrapper{
    display: flex;
    gap: 20px;
}

.secondWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    gap: 100px;
}
.secondsubWrapper{
    display: flex;
    gap:15px;
    flex-direction: column;
}

.secondWrapperText{
    color: var(--secondary-color, #092245);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.dashboardCircularbarsubText{
    color: #878A99;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 5px;
}
.secondWrapperCount{
    color: var(--secondary-color, #092245);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.6px;
}
.circularBar{
    width: 88px;
    height: 77px;
    flex-shrink: 0;
}

.dashboardChartWrapper{
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: center;
}

.dashboardChart{
    border-radius: 6px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    width: 100%;
    // height: auto;
    height: 460px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 0px;
    @media screen and (max-width: 1280px) {
        min-height: 488px;
    }
}

.dashboardCircularbar{
    width: 250px;
    height: 250px;
    flex-shrink: 0;
    align-items: center;
    align-self: center;
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}
.dashboardCircularbarText{
    color: #092245;
    font-size: 16px;
    font-weight: 600;
    line-height: 33.6px;
    @media screen and (max-width: 1280px) {
        font-size: 13px;
    }
}
.dashboardCircularbarWrapper{
    border-radius: 6px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    width: 100%;
    height: 498px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    padding: 20px 0px;
    gap:10px;
    @media screen and (max-width: 1280px) {
        min-height: 488px;
    }
}
.divider{
    background: #E9EBEC;
    width: 100%;
    height: 1px;
    margin-bottom: 10px;
}
.rectangular{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background: #6A68C5;
}
.circularProfit{
    width: 16px;
    height: 16px;
    border-radius: 8px;
    flex-shrink: 0;
    background: #7E5CD7;
}
.circularRevenue{
    width: 16px;
    height: 16px;
    border-radius: 8px;
    flex-shrink: 0;
    background: #71BFE5;
}
.circularSales{
    width: 16px;
    height: 16px;
    border-radius: 8px;
    flex-shrink: 0;
    background: #E5E5E7;
}
.rectangularLightBlue{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background: #D74B8E;
}
.graphBottomData{
    @media screen and (max-width: 1280px) {
        flex-direction: row;
        display: flex;
      }
}
.buttomWrapper{
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:10px;
    margin-bottom: 10px;
    @media screen and (max-width: 1280px) {
      flex-direction: column;
      place-items: flex-start;
      width: 100%;
      margin-bottom: 0px;
        &:last-child{
            place-items: flex-end;
            margin-bottom: 0px;
        }
    }
}

.lineChartInfoData{
    @media screen and (max-width: 1280px) {
        flex-direction: row;
        justify-content: center;
    }
}
.flex{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px
}
.marginLeft{
    margin-left: 30px;
}
.dashboardCircularbarAlign{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboardHeader{
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboardHeaderChat{
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}
.buttomWrapperText{
    color: #092245;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
}
.buttomWrapperSubText{
    color:#878A99;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.customBarChart{
    width: 100%;
    padding: 0px 20px 20px;
}
.greenText{
    color: #00A751;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.lightText{
    color: #878A99;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.rectangularGreen{
    background-color: #36C242;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.rectangularBlue{
    background-color: #4D6FFF;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
ul.recharts-default-legend{
    display: none;
}
.ml{
    margin-left: -57px;
}

.dashboardHeaderText{
    display: flex;
}
