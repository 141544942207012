@import "../../../../themes/colors.scss";
@import "../../../../styles/variable";

.cancel-icon {
  text-align: end;
}

.EquipmentReturnIcon {
  text-align: center;
  margin-bottom: 20px;
}

.modalModalHeading {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 800;
  font-size: 25px;
  color: $color_purple;
  font-family: $primaryFont;
}

.modalModalDescription {
  margin-bottom: 40px;
  color: #878a99;
  font-family: $primaryFont;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
}

.divButtons {
  text-align: center;
}

.yesBtn {
  border: 1px solid #00a751;
  font-weight: 500;
  font-size: 13px;
  background: #00a751;
  border-radius: 4px;
  color: $white;
  margin: 0px 5px;
  height: 36px;
  &:hover {
    border: 1px solid #00a751;
    background-color: #00a751;
    color: $white;
  }
}

.noBtn {
  border: 1px solid #ced4da;
  font-weight: 500;
  font-size: 13px;
  background-color: #ced4da;
  border-radius: 4px;
  color: #212529;
  margin: 0px 5px;
  height: 36px;
  &:hover {
    border: 1px solid #ced4da;
    background-color: #ced4da;
  }
}
