
@import "../../../../../themes/colors.scss";
@import "../../../../../styles/globalmixin.scss";

.vacationMainWrapper{
  background: #FFFFFF !important;
  padding: 20px 20px;
  // margin: 20px 20px;
}
.save_and_next_div {
  @include display_items(flex, flex-end, "");
}

.button_margin_left {
  margin-left: 16px;
}


.css-pwt5s4 {
   padding: 0px !important;
   border: 1px solid #e9ebec !important
  }