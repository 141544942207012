.breadcrumb {
  border-radius: 4px;
  margin: 0px;
}

.breadcrumb-item.active {
  background: #00a751;
  color: #ffffff;
  padding: 8px 19px 8px 23px;
  font-style: normal;
  font-weight: 400;
  clip-path: polygon(9% 0, 100% 0, 87% 115%, 0% 115%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.breadcrumb-item {
  color: #909c95;
  background-color: #d9d9d9;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
  align-content: center;
  margin-bottom: 6px;
  height: 54px;
  a {
    text-decoration: none;
    margin-left: 10px;
    color: #909c95;
    background-color: #d9d9d9;
  }
  @media screen and (max-width: 768px) {
    padding: 4px 19px 4px 12px !important;
  }
}

.breadcrumb-item {
  &::before {
    display: none;
  }
  &:first-child {
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  }
}

.breadcrumb_container {
  padding: 20px 25px 0px 25px;
}
