@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$primaryFont: 'Inter', sans-serif;
$primaryColor: #00a751;
$secondaryColor: #CED4DA;
$dark_secondaryColor: #b2b6ba;
$labelColor: #212529;
$color_bottom: #E9EBEC;
$color_purple: #092245;
$color_white: #FFFFFF;
$color_orange: #F06548;
$color_dark_orange: #e15337;
$color_red: #DC2800;
$color_green: #1BBD01;
$color_DarkBlue: #021BFF;
$color_pink: #FC06F2;
$color_voilet: #9747FF;
$color_skyBlue: #299CDB;
$color_grey: #878A99;
$color_yellow: #F7B84B;