@import '../../../../themes/colors.scss';
@import '../../../../styles/variable';

.cancel-icon {
    text-align: end;
}

.deleteIcon {
    text-align: center;
}

  .modalModalHeading{
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    color: $color_purple;
    font-family: $primaryFont;
  }

  .modalModalDescription{
    margin-bottom: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    color: #878A99;
    font-family: $primaryFont;
  }

.divButtons {
    text-align: center;
}

.deleteBtn {
    border: 1px solid $color_orange;
    font-weight: 500;
    font-size: 13px;
    background-color: $color_orange;
    border-radius: 4px;
    color: $white;
    margin: 0px 5px;
    height: 36px;
    &:hover{
      border: 1px solid $color_dark_orange;
      background-color: $color_dark_orange;
      color: $white;
    }
  }

  .cancelBtn {
    border: 1px solid $secondaryColor;
    font-weight: 500;
    font-size: 13px;
    background-color: $secondaryColor;
    border-radius: 4px;
    color: #212529;
    margin: 0px 5px;
    height: 36px;
    &:hover{
      border: 1px solid $dark_secondaryColor;
      background-color: $dark_secondaryColor;
    }
  }