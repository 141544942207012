@import '../../../../../themes/colors.scss';
@import "../../../../../styles/globalmixin.scss";

.customerInfo {
  font-weight: 600;
  font-size: 15px;
  color: #212529;
}
.cancel-icon {
    text-align: end;
}

.customerInfo_main_container {
  padding: 16px 20px;
}

.div_label_text {
  display: flex;
  flex-direction: column;
}
.label_Style_Customer {
  font-weight: 500;
  font-size: 13px;
  color: $cyanBlueColor;
  margin-bottom: 9px;
}  

.save_and_next_div {
  @include display_items(flex, flex-end, "");
}

.button_margin_left {
  margin-left: 16px;
}

.button_div{
  justify-content: end;
  margin-top: 60px !important;
}
// .button_save_and_next {
//   background-color: $primaryColor !important;
//   color: $backGroundColor_white !important;
//   font-size: 13px !important;
//   font-weight: 500;
//   height: 40px;
//   text-transform: capitalize !important;
// }