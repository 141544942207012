@import "../../../themes/colors.scss";
@import "../../../styles/globalmixin.scss";

.container_ {
    padding: 15px 24px;
    display: flex;
    align-items: center;
    background: $backGroundColor_white;
    justify-content: space-between;
  }

.image_container_style{
  padding: 0px 0px 0px 0px !important;
}