.container_div{
    display: flex;
    flex-wrap: wrap;
}

.commentsCard{
    padding: 2px 16px 16px 16px;
    margin: 0px 0px 0px 16px;
    width: 100%;
}

.commentsText{
    div{
        textarea{
          font-size: 13px !important;
        }
    }
}