@import "../../themes/colors.scss";

.dropdown {
  // margin-left: 60px !important;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.dropdown-content {
  width: 329px;
  top: 30px;
  right: 10px;
  overflow-y: auto;
  border-radius: 10px;
  position: absolute;
  background-color: $backGroundColor_white;
  min-width: 160px;
  // max-width: 329px;
  // margin-top: 175px;
  height: 362px;
  box-shadow: 0px 8px 16px 0px $color_black;
  z-index: 9;
  @media screen and (max-width: 480px) {
    width: 320px;
    top: 34px;
    right: -75px;
  }
}

.dropdown-content::-webkit-scrollbar {
  height: 6px;
  width: 4px;
}

.dropdown-content::-webkit-scrollbar-track {
  background: $backGroundColor_white;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: $backGroundColor_MOURN_MOUNTAIN_SNOW;
  border-radius: 10px;
}
.dropdown-content::-webkit-scrollbar-thumb:hover {
  background: $backGroundColor_MOURN_MOUNTAIN_SNOW;
}

.div-style_title {
  padding: 16px 20px;
  position: sticky;
  top: 0;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: $white;
  background-color: $backGroundColor_DARK_ECLIPSE;
  font-weight: 600;
  font-size: 15px;
}
.ul-style {
  text-align: left;
  padding: 0 0 0px 0 !important;
  margin: 0px;
  font-size: 14px;
  // overflow-y: auto;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
  color: $color_SEARED_GREY;
  li {
    list-style: none;
    a {
      text-decoration: none;
      color: $color_SEARED_GREY ;
    }
    .div2 {
      padding: 8px 18px !important;
      display: flex;
      align-items: center;
    }
    .div2:hover {
      background-color: #f4fafd;
    }
  }
}
.profile {
  font-weight: 500;
  text-transform: none;
  margin-left: 20px;
}
.view-all {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  position: sticky;
  bottom: 0;
  cursor: pointer;
  background-color: $backGroundColor_white;
  color: $primaryColor;
  font-size: 13px;
}

.profile-dropdown-content {
  width: 199px;
  top: 46px;
  right: -10px;
  overflow-y: auto;
  border-radius: 10px;
  position: absolute;
  background-color: $backGroundColor_white;
  height: auto;
  box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.div-style-profile {
  padding: 16px 18px 8px 18px;
  position: sticky;
  top: 0;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #878A99;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.sub_header_container {
  padding: 5px 24px;
  display: flex;
  align-items: center;
  background: #ffff;
  justify-content: space-between;
  height: 55px;
}

.sub_heading {
  font-weight: 600;
  font-size: 17px;
  line-height: 34px;
  text-transform: uppercase;
}
.exportCSV {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $color_DUSTY_CHIMNEY;
  padding-right: 8px;
}

.print_container {
  display: flex;
  align-items: center;
}

.img_container_navbar {
  display: flex;
  place-items: baseline;
}

.menu1 {
  margin: 7px;
}
.sideBarMenu{
font-size: 14px;
font-weight: 500;
}

.text_align{
  text-align: center;
}

.activeBackground {
  background-color: rgba(0, 167, 81, 0.1);
}
