
@import "../../../../themes/colors.scss";
@import "../../../../styles/globalmixin.scss";

  .customerInfo {
    font-weight: 600;
    font-size: 15px;
    color: $cyanBlueColor;
  }
  .customerInfo_main_container {
    padding: 16px 20px;
  }

  .div_label_text {
    display: flex;
    flex-direction: column;
    min-width: 70px;
  }
  .no_label_Style {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 30px;
  }
  .label_Style_Customer {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 9px;
  }      

  .dropdown_container {
  display: flex;
  align-items: center;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root{
  box-shadow: none !important;
}

.billing-checkbox{
  display: flex;
  justify-content: flex-end;
}
.div_container_CustomerInfo {
  // margin: 20px 0px;
  background: #f3f3f9;
}
.save_and_next_div {
  @include display_items(flex, flex-end, "");
}

.button_cancel {
  background-color: $background-color-cyan-blue !important;
  color: $cyanBlueColor !important;
  font-size: 13px !important;
  font-weight: 500;
  height: 40px;
  text-transform: capitalize !important;
}

.button_save_and_next{
  height: 40px;
}
.button_margin_left {
  margin-left: 16px;
}


.css-pwt5s4 {
   padding: 0px !important;
   border: 1px solid #e9ebec !important
  }

  .customer_info_header_checkbox{
    display: flex;
  }
