.theme_container{
    padding: 20px 20px 0px 20px;
}

.update_logo {
  padding: 9px 20px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 4px;
  font-size: 15px;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.theme_left_border{
    border-left: 3px solid #00A751; 
    margin-right: 16px;
    border-radius: 3px;
}

.save_cancel{
    display: flex;
    justify-content: flex-end;
    padding: 30px 20px 25px 20px;
}

.colorsDiv{
    padding-bottom: 40px;
}

.color_main_container{
    display: flex;
    column-gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    transition: flex-wrap 1s ease;
    padding: 0px 10px;
}

.form_file_upload {
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
.input_file_upload {
    display: none;
  }
  
.label_file_upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    padding: 10px 20px;
    margin: 20px 0px;
    border-radius: 4px;
    border: 1px dashed #ced4da;
    background: rgba(41, 156, 219, 0.05);
  }
  
  .upload_button {
    cursor: pointer;
    padding: 10px 18px;
    border-radius: 4px;
    background: #00a751;
    border: none;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    color: #ffffff;
  }
  
  .upload_button:hover {
    background: #139b55;
  }
  
  .drag_file_element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  
  .drag_drop_text {
    font-size: 15px;
    max-width: 290px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    margin: 10px 0px !important;
    line-height: 21px;
    color: rgba(135, 138, 153, 1);
  }
  
  .color_picker_container_no_icon {
      position: relative;
      margin-top: 20px;
      width: 34px;
      height: 34px;
      overflow: hidden;
      border-radius: 50%;
  }
  
  .circular_input_no_icon {
      position: absolute;
      cursor: pointer;
      appearance: none;
      width: 45px;
      height: 45px !important;
      overflow: hidden;
      border: 1px solid #000000;
      top: -4px;
      left: -4px;
  }
  
  .color_picker_container {
    position: relative;
    margin-top: 20px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .circular_input {
    position: absolute;
    cursor: pointer;
    appearance: none;
    width: 45px;
    height: 45px !important;
    overflow: hidden;
    border: 1px solid #000000;
    top: -4px;
    left: -4px;
    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 10px;
      width: 22px;
      height: 22px;
      background: url("../../../../assets/drawIcon.svg");
    }
  }

  .circular_div{
    position: absolute;
    cursor: pointer;
    appearance: none;
    width: 45px;
    height: 45px !important;
    overflow: hidden;
    border: 1px solid #000000;
    top: -4px;
    left: -4px;
    &::before {
      content: "";
      position: absolute;
      top: 11px;
      left: 9px;
      width: 21px;
      height: 17px;
      background: url("../../../../assets/themesRight.svg");
    }
  }
