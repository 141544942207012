.effectiveDateClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  &_firstText {
    border: 1px solid #e9ebec;
    background: linear-gradient(0deg, #e9ebec, #e9ebec),
      linear-gradient(0deg, #eef1f4, #eef1f4);
    width: 160px;
    height: 40px;
    display: flex;
    padding-left: 5px;
    border-radius: 4px;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
  }
}

.backgroundWrapper {
  background-color: #ffffff;
  margin: 30px;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.agreementClassName {
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
  justify-content: flex-end;
}

.bottomWrapper {
  background: #00a751;
  border-radius: 6px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.itemTextClass {
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.firstText {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  color: #000000;
}

.secondText {
  font-size: 14px;
  font-weight: 700;
  // line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.divider {
  height: 2;
  background: #e9ebec;
}

.agreementText {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: justify;
  color: #000000;
}
.thankYouText {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: justify;
  color: #000000;
}
.bottomTextClass {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.inputBoxHeight {
  height: 40px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.closeIconClass {
  cursor: pointer;
  z-index: 9999;
}
.tableHeadPdfBid {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #e9ebec;
  border-bottom: 1px solid #e9ebec;
  background-color: #eef1f4;
}

.tableHeadPdfBid th {
  text-align: center;
  border-radius: 0px;
  font-size: 12px;
  font-weight: 700;
  padding: 11px 0px;
  margin-bottom: 2px;
}

.tableBodyPdfBid {
  display: flex;
  text-align: justify;
}

.tableBodyPdfBid td {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 11px;
  word-break: break-all;
  min-height: 75px;
  border: 1px solid #e9ebec;
}

.printButton {
  background-color: #f06548 !important;
  color: #ffffff !important;
  font-size: 13px;
  font-weight: 500;
  padding: 9px 18px !important;
  line-height: 1.75;
  text-transform: capitalize !important;
  height: 40px;
}

.mt {
  margin-top: 30px;
}

.bottomWrapperMain {
  background: #00a751;
  border-radius: 6px;
  height: 54px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.bidPdfHeaderLogoContainer{
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
  @media screen and (max-width: '600px') {
    flex-direction: column;
    img{
      margin-bottom: 20px;
    }
  }
}

.quotaPdfContainer{
  display: flex;
  justify-content: space-between;
  padding: 30px 30px;
  align-items: center;
}

@media screen and (max-width: '600px') {
  .quotaPdfContainer{
    flex-direction: column;
    justify-content: flex-start;
  }

  .quotaPdfBox{
    width: 100%;
    margin-bottom: 25px;
  }

  .bidItemsGroup{
    padding: 17px !important;
  }
}

