@import "../../styles/breakpoint";
.cards{
    margin:20px;
    border-radius: 6px;
    @media screen and (max-width: $tablet) {
        margin: 8px;
    }
}
.cardContainer{
    min-height: 614px;
    @media screen and (max-width: $tablet) {
        min-height: auto;
    }
}

.allCenter{
    display: flex;
    justify-content: center;
    place-items: center;
}

.text_fieldForm{
    margin: 42px 35px;
    background-color: #F3F3F9;
}

.field_data{
    margin-left: 15%;
}

.update_btn{
    color: #fff;
    background-color: #00A751;
    width: 52%;
    margin: 20px;
}

.inputFieldText{
    background-color: #fff;
}