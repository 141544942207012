.InspectionText {
  padding: 20px;
}

.RecommendationText {
  div {
    background-color: white !important;
  }
}

.ArrowDiv {
  width: 22px;
  height: 178px;
  background-color: #e9ebec;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
  &:hover {
    background-color: #d1d4d6;
  }
}

.InspectionDiv {
  font-weight: 600;
  font-size: 15px;
  padding: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.gridVisible {
  animation: fadeIn 0.7s ease-in-out;
}

.gridHidden {
  animation: fadeOut 0.7s ease-in-out;
}