.container {
    position: relative;
  }
  
  .pipe.horizontal {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 120px; /* Adjust pipe thickness */
    background-color: #9fd7ef;
    border-left: 8px solid black;
    border-right: 8px solid black;
  }
  
  .verticalLine {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px; /* Adjust line thickness */
    background-color: rgb(0, 0, 0);
  }
  
  .divContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
  }
  
  .lastText {
    font-size: 13px;
    position: absolute !important;
    top: 68px !important;
    right: 0px !important;
    font-weight: 500 !important;
    padding: 3px;
    margin-right: 8px !important;
    text-align: center !important;
    background-color: white;
    border-radius: 50px;
    background: linear-gradient(145deg, #7ab9d4, #90dcfb);
    box-shadow: 5px 5px 45px #4e7788, -5px -5px 45px #c0ffff;
  }
  
  .AllText {
    position: absolute;
    top: 32px;
    font-weight: 500 !important;
    font-size: 13px;
    padding: 3px;
    text-align: center;
    // background-color: white;
    border-radius: 50px;
    background: linear-gradient(145deg, #7ab9d4, #90dcfb);
    box-shadow: 5px 5px 45px #4e7788, -5px -5px 45px #c0ffff;
  }
  
  .bayLabel {
    position: absolute;
    top: 75px;
    font-weight: 500 !important;
    font-size: 13px;
    padding: 3px;
    border: 2px solid black;
    text-align: center;
    border-radius: 50px;
    background: linear-gradient(145deg, #90dcfb, #7ab9d4);
    box-shadow: 5px 5px 10px #7bbbd6, -5px -5px 10px #93e1ff;
  }
  
  .lastBayLabel {
    font-size: 13px;
    position: absolute !important;
    top: 110px !important;
    right: 0px !important;
    font-weight: 500 !important;
    padding: 3px;
    margin-right: 8px !important;
    text-align: center !important;
    border: 2px solid black;
    border-radius: 50px;
    background: linear-gradient(145deg, #90dcfb, #7ab9d4);
    box-shadow: 5px 5px 10px #7bbbd6, -5px -5px 10px #93e1ff;
  }

  .testTextStyle {
    margin-top: 125px;
    padding: 10px;
    font-weight: 600 !important;
  }

  .innerDiv {
    position: relative;
    min-width: 400px;
    max-width: 100%;
    padding: 10px;
  }

  @media screen and (max-width: '600px') {
  .innerDiv {
    min-width: unset;
    }
}

  .SupportLabel {
    position: absolute;
    top: 0px;
    font-weight: 400 !important;
    font-size: 10px;
    padding: 2px;
    text-align: right;
  }
  
  .BayDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .BayLengthBtn{
    display: flex;
    justify-content: space-between;
  }

  .BayGenBtn{
    margin-left: 8px;
  }
  @media screen and (max-width: 441px) {
    .BayGenBtn {
      margin-left: 4px;
    }
    .BayLengthBtn{
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
    }
  }