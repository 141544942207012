.custom-radio-label .MuiFormControlLabel-label {
  font-size: 14px;
  margin-left: 5px;
}

.custom-radio-label{
  height: 34px !important;
}

.errorMessage {
  color: red;
  margin-left: 5px;
}
