
@import "../../../themes/colors.scss";
@import "../../../styles/globalmixin.scss";
@import "../../../styles/breakpoint";


  #add_customer_of_smc {
    position: relative;
  }
  .customerInfo {
    font-weight: 600;
    font-size: 15px;
    color: $cyanBlueColor ;
  }
  .customerInfo_main_container {
    padding: 16px 20px;
  }

  .div_label_text {
    display: flex;
    flex-direction: column;
  }
  .no_label_Style {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 30px;
  }
  .label_Style_Customer {
    font-weight: 500;
    font-size: 13px;
    color: $cyanBlueColor;
    margin-bottom: 9px;
  }      

  .dropdown_container {
  display: flex;
  align-items: center;
}
.billing-checkbox{
  display: flex;
  justify-content: flex-end;
}
.div_container_CustomerInfo {
  // margin: 20px 0px;
  background: #f3f3f9;
}
.save_and_next_div {
  @include display_items(flex, flex-end, "");
}

.button_margin_left {
  margin-left: 16px;
}


.Style_icon{
  margin-top: 51px;
  margin-left: 10px;
  border: none;
  background: none;
  &.disabled{
    filter: grayscale(1);
    opacity: 0.5;
  }
  @media screen and (max-width: $mobile) {
    position: absolute;
    right: 4px;
    top: -45px;
  }
}

.sub_heading {
  @media screen and (max-width: $mobile) {
    font-size: 14px;
    text-transform: capitalize;
    line-height: 18px;
  }
}

.cancel-icon {
  text-align: end;
}
.separate_section{
  background-color: $backGroundColor_white;
}

.customerSeeMore{
  cursor: pointer;
  padding: 30px;
}

.alignArrow{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}