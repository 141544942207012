.dashboardCardWrapper {
  // margin: 15px;
  display: flex;
  justify-content: space-around;
}

.emptySpace {
  height: 57px;
  flex-shrink: 0;
  background: #FFF;
}