
@import "../../themes/colors.scss";
@import "../../styles/globalmixin.scss";
@import "../../styles/breakpoint";

  .customerInfo {
    font-weight: 600;
    font-size: 15px;
    color: $cyanBlueColor ;
  }

.cancel-icon {
    text-align: end;
  }
  .save_and_next_div {
    @include display_items(flex, flex-end, "");
  }

  .button_margin_left {
    margin-left: 16px;
  }
  
  