@mixin display_items($flex, $content, $alignItem) {
  display: $flex;
  justify-content: $content;
  align-items: $alignItem;
}

@mixin horizontal_divider($width, $height, $background) {
  width: $width;
  height: $height;
  background: $background;
}
