@import "../../themes/colors.scss";
@import "../../styles/variable";

.cancel-icon {
  text-align: end;
}

.confirmationIcon {
  display: flex;
  text-align: center;
  height: 70px;
  width: 70px;
  border-radius: 35px;
  background-color: #00a751;
  justify-content: center;
  align-items: center;
}

.modalModalHeading {
  margin-top: 30px;
  margin-bottom: 20px;
  color: $color_purple;
  font-family: $primaryFont;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
}

.modalModalDescription {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  color: #878a99;
  font-family: $primaryFont;
}

.divButtons {
  text-align: center;
}

.yesBtn {
  border: 1px solid #00a751;
  font-weight: 500;
  font-size: 13px;
  background-color: #00a751;
  border-radius: 4px;
  color: $white;
  margin: 0px 5px;
  height: 36px;
  &:hover {
    border: 1px solid #00a751;
    background-color: #00a751;
    color: $white;
  }
}

.cancelBtn {
  border: 1px solid $secondaryColor;
  font-weight: 500;
  font-size: 13px;
  background-color: $secondaryColor;
  border-radius: 4px;
  color: #212529;
  margin: 0px 5px;
  height: 36px;
  &:hover {
    border: 1px solid $dark_secondaryColor;
    background-color: $dark_secondaryColor;
  }
}
