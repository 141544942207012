.spanStyle {
    width: 100%;
    width: calc(100% - 80px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topIconImage{
    z-index: 9;
}
// @media screen and (max-width: 568px) {
//     .spanStyle {
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         width: 179px;
//     }
// }