.tableContainerContent {
  padding: 20px 30px;
}
.shippingListClass {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 50px;
}

.instructionClass {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}
.instructionClassSpan {
  font-size: 15px;
  font-weight: 400;
}
.instructionHeading{
  font-weight: 600;
  margin: 16px 0px 0px 0px;
  padding: 0;
}

.customBreakWords{
  word-wrap: break-word !important;
  white-space: break-spaces;
  width: 100%;
  word-break: break-all;
}

.save_and_next_div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.tableRowClass {
  background-color: #ced4da;
}
